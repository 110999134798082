import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
  TextField,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
//import { fetchJobAssets, fetchProducts } from '../api';
import { fetchProducts } from '../api';
import JSZip from 'jszip';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { auth } from '../firebase';

function StudioVaultDialog({
  open,
  onClose,
  customerId,
  jobId,
  productId,
  params,
  //apiMode = 'jobAssets',
}) {
  console.log('StudioVaultDialog opened'); // Remove apiMode reference

  const [productList, setProductList] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [filters, setFilters] = useState({
    sku: '',
    name: '',
    description: '',
  });
  const [imageCache, setImageCache] = useState({});
  const [largeImage, setLargeImage] = useState(null);
  const imageCacheRef = useRef({});
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [downloadOptionsOpen, setDownloadOptionsOpen] = useState(false);
  const [folderOption, setFolderOption] = useState('noSubfolders');
  const [fileNamingOption, setFileNamingOption] = useState('skuSequence');

  useEffect(() => {
    console.log('useEffect triggered with:', {
      open,
      customerId,
      params,
      //apiMode,
    });

    const fetchData = async () => {
      try {
        setProductList([]);
        const queryParams = new URLSearchParams(params);

        if (jobId) {
          queryParams.append('job_id', jobId);
        }
        if (productId) {
          queryParams.append('product_id', productId);
        }

        console.log('Query Params:', queryParams.toString());
        const data = await fetchProducts(queryParams.toString());
        console.log('Fetched Data:', data);
        setProductList(data.products || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (open && customerId) {
      fetchData();
    }
  }, [open, customerId, jobId, productId, params]);

  const loadThumbnails = () => {
    const newImageCache = { ...imageCacheRef.current };

    for (const product of productList) {
      if (!newImageCache[product.product_name]) {
        newImageCache[product.product_name] = [];
      }

      const images = product.assets || [];

      images.forEach((asset) => {
        if (
          !newImageCache[product.product_name].some(
            (a) => a.asset_id === asset.asset_id
          )
        ) {
          newImageCache[product.product_name].push({
            ...asset,
            product_name: product.product_name,
            thumbnailUrl: null,
            imageUrl: null,
            loading: true,
          });

          (async () => {
            try {
              const thumbnailRef = ref(storage, asset.thumbnail_storage_path);
              const thumbnailUrl = await getDownloadURL(thumbnailRef);

              setImageCache((prevImageCache) => {
                const updatedProductImages = prevImageCache[
                  product.product_name
                ].map((a) => {
                  if (a.asset_id === asset.asset_id) {
                    return {
                      ...a,
                      thumbnailUrl,
                      loading: false,
                    };
                  }
                  return a;
                });

                return {
                  ...prevImageCache,
                  [product.product_name]: updatedProductImages,
                };
              });
            } catch (error) {
              console.error(
                'Error generating thumbnail URL for asset:',
                asset.asset_id,
                error
              );
              setImageCache((prevImageCache) => {
                const updatedProductImages = prevImageCache[
                  product.product_name
                ].map((a) => {
                  if (a.asset_id === asset.asset_id) {
                    return {
                      ...a,
                      loading: false,
                      error: true,
                    };
                  }
                  return a;
                });

                return {
                  ...prevImageCache,
                  [product.product_name]: updatedProductImages,
                };
              });
            }
          })();
        }
      });
    }
    imageCacheRef.current = newImageCache;
    setImageCache(newImageCache);
  };

  /*useEffect(() => {
    if (productList.length > 0) {
      loadThumbnails();
    }
  }, [productList]);*/

  // Updated useEffect for asynchronous thumbnail loading with placeholders
  useEffect(() => {
    if (productList.length > 0) {
      loadThumbnails();
    }
  }, [productList]);

  const handleImageClick = (assetId) => {
    setSelectedImages((prev) => {
      const updated = new Set(prev);
      updated.has(assetId) ? updated.delete(assetId) : updated.add(assetId);
      return updated;
    });
  };

  const handleDoubleClick = (asset) => {
    if (!asset) return; // Prevent action if asset is not available
    setIsLoadingImage(true);

    const fetchImageUrl = async () => {
      try {
        let imageUrl = asset.imageUrl;
        if (!imageUrl) {
          // Fetch the imageUrl
          const imageRef = ref(storage, asset.storage_path);
          imageUrl = await getDownloadURL(imageRef);

          // Update the imageCache
          setImageCache((prevImageCache) => {
            const updatedProductImages = prevImageCache[asset.product_name].map(
              (a) => {
                if (a.asset_id === asset.asset_id) {
                  return {
                    ...a,
                    imageUrl,
                  };
                }
                return a;
              }
            );
            return {
              ...prevImageCache,
              [asset.product_name]: updatedProductImages,
            };
          });
        }
        setLargeImage(imageUrl);
      } catch (error) {
        console.error('Error fetching full image URL:', error);
      } finally {
        setIsLoadingImage(false);
      }
    };
    fetchImageUrl();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectAll = () => {
    const allImages = new Set(
      productList.flatMap((product) =>
        (imageCache[product.product_name] || []).map((asset) => asset.asset_id)
      )
    );
    setSelectedImages(allImages);
  };

  const handleDeselectAll = () => {
    setSelectedImages(new Set());
  };

  const handleDownloadSelected = () => {
    // Open the new "Download Options" dialog
    setDownloadOptionsOpen(true);
  };

  //const handleDownloadSelected = async () =>
  const beginDownload = async () => {
    setDownloadOptionsOpen(false); // Close the "Download Options" dialog
    setIsDownloading(true); // Show spinner

    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const zip = new JSZip();

    console.log('Starting ZIP creation...');
    console.log('Selected Image IDs:', Array.from(selectedImages));

    for (const product of productList) {
      const productFolderName = `${product.product_code}_${product.product_name.replace(
        / /g,
        '_'
      )}`;
      let folder;
      if (folderOption === 'noSubfolders') {
        folder = zip; // Use root folder for "No Subfolders"
      } else {
        folder = zip.folder(productFolderName); // Create subfolder per product
        console.log(`Creating folder: ${productFolderName}`);
      }

      const images = imageCache[product.product_name] || [];
      for (const asset of images) {
        if (selectedImages.has(asset.asset_id)) {
          console.log(`Image selected: ${asset.asset_id}`);
          try {
            // Create a reference using the storage path
            const storageRef = ref(storage, asset.storage_path);

            // Get the download URL
            const signedUrl = await getDownloadURL(storageRef);

            // Fetch the file
            const response = await fetch(signedUrl);
            if (!response.ok) {
              console.error(
                `Failed to fetch image: ${signedUrl}`,
                response.statusText
              );
              continue;
            }

            const blob = await response.blob();

            // Extract file name
            /*const originalFileName = asset.storage_path.split('/').pop();
            const fileName = originalFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');*/
            // Generate the file name based on the selected option
            let fileName;
            const sequenceNumber = images.indexOf(asset) + 1; // Generate sequence number
            const originalExtension = asset.storage_path.split('.').pop(); // Get original file extension

            if (fileNamingOption === 'skuSequence') {
              fileName = `${product.product_code}_${sequenceNumber}.${originalExtension}`;
            } else if (fileNamingOption === 'skuNameSequence') {
              fileName = `${product.product_code}_${product.product_name.replace(/ /g, '_')}_${sequenceNumber}.${originalExtension}`;
            } else {
              fileName = asset.storage_path.split('/').pop(); // Default fallback
            }

            // Sanitize the file name
            fileName = fileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');

            folder.file(fileName, blob);
            console.log(`Added image to folder: ${fileName}`);
          } catch (error) {
            console.error(
              `Error fetching image or creating file for: ${asset.storage_path}`,
              error
            );
          }
        } else {
          console.log(`Image not selected, skipping: ${asset.asset_id}`);
        }
      }
    }

    console.log('Generating ZIP...');
    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        console.log('ZIP generated successfully.');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'selected_images.zip';
        link.click();
      })
      .catch((error) => {
        console.error('Error generating ZIP:', error);
      })
      .finally(() => {
        setIsDownloading(false); // Hide spinner
      });
  };

  const filteredProducts = productList.filter(
    (product) =>
      (filters.sku === '' || product.product_code.includes(filters.sku)) &&
      (filters.name === '' ||
        product.product_name
          .toLowerCase()
          .includes(filters.name.toLowerCase())) &&
      (filters.description === '' ||
        product.product_description
          .toLowerCase()
          .includes(filters.description.toLowerCase()))
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ height: '80vh', margin: 'auto' }}
    >
      <DialogContent>
        <Typography variant="h5" align="center" gutterBottom>
          StudioVault ({filteredProducts.length} Products)
        </Typography>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <TextField
            label="SKU"
            name="sku"
            value={filters.sku}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Product Name"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Description"
            name="description"
            value={filters.description}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <Button variant="outlined" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeselectAll}
            disabled={selectedImages.size === 0}
          >
            Deselect All
          </Button>
        </Box>

        <Box sx={{ maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => {
              const images = imageCache[product.product_name] || [];

              return (
                <Box
                  key={index}
                  sx={{
                    marginBottom: 3,
                    padding: 2,
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    boxShadow: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {product.product_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      SKU: {product.product_code}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '250px',
                      }}
                    >
                      {product.product_description}
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginLeft: 'auto' }}
                  >
                    {images.map((asset, idx) => (
                      <Box
                        key={idx}
                        component="div"
                        sx={{
                          position: 'relative',
                          width: 70,
                          height: 90, // Adjusted to maintain consistent layout
                          borderRadius: 1,
                          border: '1px solid #ddd',
                          cursor: asset.loading ? 'default' : 'pointer',
                          backgroundColor: selectedImages.has(asset.asset_id)
                            ? 'rgba(0, 123, 255, 0.25)'
                            : 'transparent',
                          padding: '4px',
                          overflow: 'hidden',
                        }}
                        onClick={() => handleImageClick(asset.asset_id)}
                      >
                        {/* Shot Type and Instance */}
                        <Box
                          sx={{
                            textAlign: 'center',
                            fontSize: '10px',
                            color: 'text.secondary',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginBottom: '4px',
                            fontWeight: 'lighter',
                          }}
                          title={`${asset.shot_type_name} - Instance: ${asset.shot_instance}`} // Tooltip for full text
                        >
                          {asset.shot_type_name} - {asset.shot_instance}
                        </Box>

                        {/* Thumbnail Image */}
                        {asset.loading ? (
                          <Box
                            sx={{
                              width: '100%',
                              height: '70%', // Matches the layout height of the thumbnail
                              borderRadius: 4,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#f0f0f0',
                            }}
                          >
                            <CircularProgress size={24} />
                          </Box>
                        ) : (
                          <Box
                            component="img"
                            src={asset.thumbnailUrl}
                            alt={`Thumbnail for ${asset.shot_type_name}`}
                            sx={{
                              width: '100%',
                              height: '70%',
                              borderRadius: 4,
                              objectFit: 'cover',
                              opacity: selectedImages.has(asset.asset_id)
                                ? 0.7
                                : 1,
                              cursor: 'pointer',
                            }}
                            onDoubleClick={() => handleDoubleClick(asset)} // Double-click to view
                          />
                        )}

                        {/* Selection Indicator */}
                        {selectedImages.has(asset.asset_id) && (
                          <CheckCircleIcon
                            sx={{
                              position: 'absolute',
                              top: -4,
                              right: -4,
                              color: 'green',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              );
            })
          ) : (
            <Typography variant="body2" color="textSecondary">
              Please wait... Searching for images
            </Typography>
          )}
        </Box>

        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            onClick={handleDownloadSelected}
            disabled={selectedImages.size === 0}
          >
            Download Selected
          </Button>
        </Box>
      </DialogContent>

      {(largeImage || isLoadingImage) && (
        <Dialog
          open={!!largeImage || isLoadingImage}
          onClose={() => setLargeImage(null)}
          fullWidth
          maxWidth="xl"
          sx={{
            '& .MuiDialog-paper': {
              margin: 0,
              padding: 0,
              maxWidth: '95vw', // Fit within viewport width
              maxHeight: '95vh', // Fit within viewport height
              overflow: 'hidden', // No unnecessary scrollbars
            },
          }}
        >
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              backgroundColor: '#000', // Black background for better contrast
            }}
          >
            {isLoadingImage ? (
              <CircularProgress />
            ) : (
              <TransformWrapper>
                <TransformComponent>
                  <img
                    src={largeImage}
                    alt="Large view"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain', // Maintain aspect ratio
                      width: 'auto',
                      height: 'auto',
                      backgroundColor: '#000',
                    }}
                  />
                </TransformComponent>
              </TransformWrapper>
            )}
          </DialogContent>
          <IconButton
            onClick={() => setLargeImage(null)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 1)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
      )}

      {isDownloading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2000,
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Dialog
        open={downloadOptionsOpen}
        onClose={() => setDownloadOptionsOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {/* Title */}
          <Typography
            variant="h5"
            gutterBottom
            textAlign="center"
            sx={{ fontWeight: 'bold', marginBottom: 3 }}
          >
            Download Options
          </Typography>

          {/* Folder Generation Section */}
          <Box
            mb={4}
            p={2}
            sx={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#fafafa',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'medium',
                color: 'text.primary',
                marginBottom: 1,
              }}
            >
              Folder Generation
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', marginBottom: 2 }}
            >
              Choose how the files will be organized in folders.
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={folderOption}
                onChange={(e) => setFolderOption(e.target.value)}
              >
                <Box sx={{ marginBottom: 3 }}>
                  <FormControlLabel
                    value="noSubfolders"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'medium', color: 'text.primary' }}
                      >
                        No Subfolders
                      </Typography>
                    }
                    sx={{ alignItems: 'center' }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 4.5, // Align with radio button text
                      marginTop: '-4px', // Reduce space between radio and description
                      color: 'text.secondary',
                    }}
                  >
                    All files will be downloaded directly without organizing
                    them into folders.
                  </Typography>
                </Box>

                <Box sx={{ marginBottom: 3 }}>
                  <FormControlLabel
                    value="subfolderPerProduct"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'medium', color: 'text.primary' }}
                      >
                        Subfolder per Product
                      </Typography>
                    }
                    sx={{ alignItems: 'center' }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 4.5, // Align with radio button text
                      marginTop: '-4px', // Reduce space between radio and description
                      color: 'text.secondary',
                    }}
                  >
                    Files will be organized into separate subfolders for each
                    product.
                  </Typography>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          {/* File Naming Section */}
          <Box
            mb={4}
            p={2}
            sx={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#fafafa',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'medium',
                color: 'text.primary',
                marginBottom: 1,
              }}
            >
              File Naming
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', marginBottom: 2 }}
            >
              Choose the format for naming the downloaded files.
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={fileNamingOption}
                onChange={(e) => setFileNamingOption(e.target.value)}
              >
                <Box sx={{ marginBottom: 3 }}>
                  <FormControlLabel
                    value="skuSequence"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'medium', color: 'text.primary' }}
                      >
                        Product SKU _ Sequence Number
                      </Typography>
                    }
                    sx={{ alignItems: 'center' }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 4.5, // Align with radio button text
                      marginTop: '-4px', // Reduce space between radio and description
                      color: 'text.secondary',
                    }}
                  >
                    Files will be named using the product SKU followed by a
                    sequence number.
                  </Typography>
                </Box>

                <Box sx={{ marginBottom: 3 }}>
                  <FormControlLabel
                    value="skuNameSequence"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'medium', color: 'text.primary' }}
                      >
                        Product SKU _ Name _ Sequence Number
                      </Typography>
                    }
                    sx={{ alignItems: 'center' }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 4.5, // Align with radio button text
                      marginTop: '-4px', // Reduce space between radio and description
                      color: 'text.secondary',
                    }}
                  >
                    Files will be named using the product SKU, product name, and
                    sequence number.
                  </Typography>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          {/* Action Button */}
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              onClick={beginDownload}
              disabled={isDownloading}
              sx={{
                padding: '10px 20px',
                fontSize: '16px',
                fontWeight: 'bold',
                textTransform: 'none',
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
            >
              Begin Download
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
}

export default StudioVaultDialog;
