import React from 'react';
import UadminBaseComponent from './UadminBaseComponent';
import {
  fetchProductStatuses,
  updateProductStatus,
  updateBulkProductStatus,
} from '../../api';

function StylingUadminComponent({ job, step }) {
  const fetchStatuses = () =>
    fetchProductStatuses(
      //job.customer_id,
      job.job_id,
      job.workflow_id,
      step.step_id
    );

  const updateStatus = (productId, newStatus) =>
    updateProductStatus({
      ...job,
      step_id: step.step_id,
      product_id: productId,
      new_status: newStatus,
    });

  const updateBulkStatus = (productIds, newStatus) =>
    updateBulkProductStatus({
      ...job,
      step_id: step.step_id,
      product_ids: productIds,
      new_status: newStatus,
    });

  const handleIndividualEdit = (product, setDialog) => {
    setDialog({
      open: true,
      message: `The Styling step does not need to perform edit for product: ${product?.product_name || 'Unknown product'}.`,
    });
  };

  const handleBulkEdit = (status, selectedProducts, setDialog) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      setDialog({
        open: true,
        message: `No products selected for bulk editing in status "${status}".`,
      });
      return;
    }

    const productNames = selectedProducts.map((p) => p.product_name).join(', ');
    setDialog({
      open: true,
      message: `The Styling step does not need to perform bulk editing for ${selectedProducts.length} product(s) in status "${status}": ${productNames}.`,
    });
  };

  const handleDeleteRequest = (setDialog) => {
    setDialog({
      open: true,
      message: `Delete action is not allowed in the Styling step.`,
    });
  };

  return (
    <UadminBaseComponent
      stepTitle="Styling Step"
      fetchStatuses={fetchStatuses}
      updateStatus={updateStatus}
      updateBulkStatus={updateBulkStatus}
      dialogMessages={{
        fetchError: 'Failed to fetch products for Styling step.',
      }}
      handleIndividualEdit={handleIndividualEdit}
      handleBulkEdit={(status, selectedProducts, setDialog) =>
        handleBulkEdit(status, selectedProducts, setDialog)
      }
      handleDeleteRequest={handleDeleteRequest}
    />
  );
}

export default StylingUadminComponent;
