import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { TextField, Button, Box, Typography } from '@mui/material';

function Login() {
  //const [customerId, setCustomerId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  /*const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the form's default submit behavior

    if (!customerId || !username || !password) {
      setError('Please enter Username, and Password.');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, username, password);
      localStorage.setItem('customer_id', customerId);
      navigate('/customer');
    } catch (error) {
      setError('Login failed: ' + error.message);
    }
  };*/
  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the form's default submit behavior

    if (!username || !password) {
      setError('Please enter Username and Password.');
      return;
    }

    try {
      // Perform sign-in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );

      // Retrieve the custom claims (including customer_id) dynamically after login
      const token = await userCredential.user.getIdTokenResult();
      console.log('Decoded Firebase JWT:', token);

      if (token.claims.customer_id) {
        console.log(
          'Extracted customer_id from token:',
          token.claims.customer_id
        );
        navigate('/customer'); // Navigate to the customer page after successful login
      } else {
        throw new Error('Customer ID not found in token claims.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Login failed: ' + error.message);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      minHeight="33vh"
      pt={5}
      sx={{ bgcolor: 'background.default', color: 'text.primary' }}
    >
      <Typography variant="h4" gutterBottom>
        StudioFlow
      </Typography>
      <form
        onSubmit={handleLogin}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2, width: '300px' }}
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2, width: '300px' }}
          required
          inputProps={{ autoComplete: 'organization' }}
        />
        <Button
          type="submit" // Submit button for the form
          variant="contained"
          color="primary"
          sx={{ width: '300px' }}
        >
          Login
        </Button>
      </form>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default Login;
