import React, { useState } from 'react';
import UadminBaseComponent from './UadminBaseComponent';
import {
  fetchProductStatuses,
  updateProductStatus,
  updateBulkProductStatus,
  getRequiredShots,
  getSignedUrls,
  updateUploadStatus,
} from '../../api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function CaptureUadminComponent({ job, step }) {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [dialog, setDialog] = useState({ open: false, message: '' });
  const [shotUploadStatus, setShotUploadStatus] = useState({});
  const [uploadingShots, setUploadingShots] = useState({});

  const [shotsDialogOpen, setShotsDialogOpen] = useState(false);
  const [requiredShots, setRequiredShots] = useState([]);

  const [bulkShotsDialogOpen, setBulkShotsDialogOpen] = useState(false);
  const [bulkRequiredShots, setBulkRequiredShots] = useState([]);

  const [fileOptionsDialogOpen, setFileOptionsDialogOpen] = useState(false);
  const [folderAssignment, setFolderAssignment] = useState('code'); // Default: Subfolder is product code
  const [fileAssignment, setFileAssignment] = useState('pattern'); // Default: Pattern
  const [shotSuffixList, setShotSuffixList] = useState([]); // Holds shot and suffix data

  const [selectedFiles, setSelectedFiles] = useState([]); // Stores the files selected from the folder
  const [uploadResultsDialogOpen, setUploadResultsDialogOpen] = useState(false); // Controls results dialog visibility
  const [uploadResults, setUploadResults] = useState({}); // Stores upload matching results

  const [uploadStatus, setUploadStatus] = useState({});

  const fetchStatuses = () =>
    fetchProductStatuses(job.job_id, job.workflow_id, step.step_id);
  ////////
  ////

  ////
  ////////
  const updateStatus = (productId, newStatus) =>
    updateProductStatus({
      ...job,
      step_id: step.step_id,
      product_id: productId,
      new_status: newStatus,
    });
  ////////
  ////

  ////
  ////////
  const updateBulkStatus = (productIds, newStatus) =>
    updateBulkProductStatus({
      ...job,
      step_id: step.step_id,
      product_ids: productIds,
      new_status: newStatus,
    });
  ////////
  ////

  ////
  ////////
  /*const handleIndividualEdit = async (product) => {
    try {
      const requiredShotsResponse = await getRequiredShots(
        job.customer_id,
        job.job_id,
        [product.product_id], // Pass as an array
        job.workflow_id
      );

      // Ensure type consistency for product_id comparison
      const productShots = requiredShotsResponse.find(
        (item) => item.product_id === String(product.product_id)
      );

      if (!productShots?.shots?.length) {
        setDialog({
          open: true,
          message: `No shots required for product: ${product?.product_name}.`,
        });
        return;
      }

      setRequiredShots(productShots.shots);
      setCurrentProduct(product);
      setShotsDialogOpen(true);
    } catch (error) {
      setDialog({
        open: true,
        message: `An error occurred while fetching required shots for product: ${product?.product_name}.`,
      });
    }
  };*/
  const handleIndividualEdit = async (product) => {
    if (!product) {
      setDialog({
        open: true,
        message: 'Product not found.',
      });
      return;
    }

    try {
      const requiredShotsResponse = await getRequiredShots(
        //job.customer_id,
        job.job_id,
        [product.product_id], // Pass as an array
        job.workflow_id
      );

      const productShots = requiredShotsResponse.find(
        (item) => item.product_id === String(product.product_id)
      );

      if (!productShots?.shots?.length) {
        setDialog({
          open: true,
          message: `No shots required for product: ${product?.product_name}.`,
        });
        return;
      }

      setRequiredShots(productShots.shots); // Scoped to current product
      setCurrentProduct(product); // Isolate product being edited
      setShotsDialogOpen(true); // Open the dialog for editing
    } catch (error) {
      setDialog({
        open: true,
        message: `An error occurred while fetching required shots for product: ${product?.product_name}.`,
      });
    }
  };

  ////////
  ////

  ////
  ////////
  /*
const handleFileMatching = async () => {
  console.log("Selected Files:", selectedFiles);
  console.log("Shot Suffix List:", shotSuffixList);

  const matchedShots = [];
  const unmatchedShots = [];
  const unmatchedFiles = [];
  const folderPath = selectedFiles.directory; // Simulated root folder path
  const allFiles = selectedFiles.files; // Array of all files from the directory/subdirectories

  bulkRequiredShots.forEach((product) => {
    product.shots.forEach((shot) => {
      const expectedFileName = `${product.product_code}${shotSuffixList.find(
        (entry) =>
          entry.shot === `${shot.shot_type_name} - Instance ${shot.shot_instance}`
      )?.suffix || ''}`.trim();

      // Determine search directory based on folder assignment
      let searchDirectory = folderPath;
      if (folderAssignment === 'code') {
        searchDirectory = `${folderPath}/${product.product_code}`;
      }

      // Check for matching file
      const matchedFile = allFiles.find(
        (file) =>
          file.startsWith(searchDirectory) &&
          file.endsWith(expectedFileName)
      );

      if (matchedFile) {
        matchedShots.push({ product, shot, file: matchedFile });
      } else {
        unmatchedShots.push({ product, shot });
      }
    });
  });

  // Find files not matched to any shot
  unmatchedFiles.push(
    ...allFiles.filter(
      (file) =>
        !matchedShots.some((matched) => matched.file === file)
    )
  );

  // Prepare results
  return {
    matchedCount: matchedShots.length,
    unmatchedShotCount: unmatchedShots.length,
    unmatchedFileCount: unmatchedFiles.length,
    matchedShots,
    unmatchedShots,
    unmatchedFiles,
  };
};
*/
  const handleFileMatching = async () => {
    console.log('Selected Files:', selectedFiles);
    console.log('bulkRequiredShots:', bulkRequiredShots);
    console.log('shotSuffixList:', shotSuffixList);

    const matchedShots = [];
    const unmatchedShots = [];
    const unmatchedFiles = [];

    const allFiles = selectedFiles.files;

    // Extract the filename without path and extension
    const extractFileName = (filePath) => {
      const fileNameWithExt = filePath.split('/').pop(); // Get the last segment of the path
      return fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.')); // Remove the extension
    };

    // Map over bulkRequiredShots to find matches
    bulkRequiredShots.forEach((product) => {
      product.shots.forEach((shot) => {
        // Build the expected filename for the shot
        const shotSuffix =
          shotSuffixList.find(
            (entry) =>
              entry.shot ===
              `${shot.shot_type_name} - Instance ${shot.shot_instance}`
          )?.suffix || '';

        const expectedFileName = `${product.product_code}${shotSuffix}`.trim();

        console.log(
          `Matching for Product: ${product.product_name}, Expected Filename: ${expectedFileName}`
        );

        // Check for a matching file
        const matchedFile = allFiles.find(
          (file) => extractFileName(file) === expectedFileName
        );

        if (matchedFile) {
          matchedShots.push({ product, shot, file: matchedFile });
        } else {
          unmatchedShots.push({ product, shot });
        }
      });
    });

    // Find unmatched files
    unmatchedFiles.push(
      ...allFiles.filter(
        (file) => !matchedShots.some((matched) => matched.file === file)
      )
    );

    console.log('Matched Shots:', matchedShots);
    console.log('Unmatched Shots:', unmatchedShots);
    console.log('Unmatched Files:', unmatchedFiles);

    return {
      matchedCount: matchedShots.length,
      unmatchedShotCount: unmatchedShots.length,
      unmatchedFileCount: unmatchedFiles.length,
      matchedShots,
      unmatchedShots,
      unmatchedFiles,
    };
  };

  ////////
  ////

  ////
  ////////
  const handleBulkEdit = async (status, selectedProducts, setDialog) => {
    if (!selectedProducts.length) {
      setDialog({
        open: true,
        message: '[Capture] No products selected for bulk editing.',
      });
      return;
    }
    console.log('Selected products for bulk edit:', selectedProducts);

    try {
      const productIds = selectedProducts.map((p) => p.product_id);
      const requiredShotsResponses = await getRequiredShots(
        //job.customer_id,
        job.job_id,
        productIds, // Pass all selected product IDs
        job.workflow_id
      );

      if (!requiredShotsResponses.length) {
        setDialog({
          open: true,
          message: 'No shots required for the selected products.',
        });
        return;
      }

      // Prepare data for bulk shots dialog
      console.log('Required Shots Responses:', requiredShotsResponses);
      console.log('Selected Products:', selectedProducts);

      // Prepare data for bulk shots dialog
      const bulkShots = requiredShotsResponses.map((productShots) => {
        // Normalize product_id for comparison
        const productId = String(productShots.product_id);

        // Find the matching product
        const matchingProduct = selectedProducts.find(
          (p) => String(p.product_id) === productId
        );

        console.log(
          'Matching Product for productShots:',
          productShots.product_id,
          matchingProduct
        );

        return {
          product_name: matchingProduct?.product_name,
          product_code: matchingProduct?.product_code, // Include product_code
          product_id: matchingProduct?.product_id,
          shots: productShots.shots,
        };
      });

      setBulkRequiredShots(bulkShots);
      console.log('Populated bulkRequiredShots:', bulkShots);
      setBulkShotsDialogOpen(true);
    } catch (error) {
      setDialog({
        open: true,
        message:
          'An error occurred while fetching shots for selected products.',
      });
    }
  };
  ////////
  ////

  ////
  ////////
  const initializeFileOptionsDialog = () => {
    if (!bulkRequiredShots || bulkRequiredShots.length === 0) {
      console.error(
        'bulkRequiredShots is empty. Cannot initialize shot suffix list.'
      );
      return;
    }

    const shotTypeInstances = [
      ...new Set(
        bulkRequiredShots.flatMap((product) =>
          (product.shots || []).map(
            (shot) => `${shot.shot_type_name} - Instance ${shot.shot_instance}`
          )
        )
      ),
    ];

    if (shotTypeInstances.length === 0) {
      console.error('No shot instances found in bulkRequiredShots.');
    } else {
      console.log('Populated shotTypeInstances:', shotTypeInstances);
    }

    setShotSuffixList(
      shotTypeInstances.map((instance) => ({ shot: instance, suffix: '' }))
    );

    setFileOptionsDialogOpen(true);
  };
  ////////
  ////

  ////
  ////////
  const handleShotUpload = async (shot) => {
    const shotKey = `${shot.shot_type_id}-${shot.shot_instance}`;

    try {
      setUploadingShots((prev) => ({ ...prev, [shotKey]: true }));

      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';

      const file = await new Promise((resolve, reject) => {
        input.onchange = () => resolve(input.files[0]);
        input.onerror = reject;
        input.click();
      });

      if (!file) {
        setDialog({
          open: true,
          message: `No file selected for shot: ${shot.name}.`,
        });
        return;
      }

      const payload = {
        customer_id: job.customer_id,
        job_id: job.job_id,
        product_id: currentProduct.product_id,
        workflow_id: job.workflow_id,
        step_id: step.step_id,
        shot_type_id: shot.shot_type_id,
        shot_instance: shot.shot_instance,
        files: [{ filename: file.name, mime_type: 'image/jpeg' }],
      };

      const signedUrlResponse = await getSignedUrls(
        job.customer_id,
        job.job_id,
        currentProduct.product_id,
        payload.files,
        shot.id
      );

      if (!signedUrlResponse?.urls?.length) {
        setDialog({
          open: true,
          message: `Failed to generate signed URL for shot: ${shot.name}.`,
        });
        return;
      }

      const { signed_url: signedUrl, storage_path: storagePath } =
        signedUrlResponse.urls[0];

      // Move from desktop to firebase
      const uploadResponse = await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/jpeg' },
        body: file,
      });

      if (!uploadResponse.ok) {
        setDialog({
          open: true,
          message: `File upload failed for shot: ${shot.name}.`,
        });
        return;
      }

      const assets = [
        {
          filename: file.name,
          storage_path: storagePath,
          shot_id: shot.id,
        },
      ];

      const updateResponse = await updateUploadStatus(
        //job.customer_id,
        job.job_id,
        currentProduct.product_id,
        job.workflow_id,
        step.step_id,
        shot.shot_type_id,
        shot.shot_instance,
        assets
      );

      if (updateResponse.error) {
        setDialog({
          open: true,
          message: `Upload status update failed: ${updateResponse.error}`,
        });
        return;
      }

      // Update requiredShots for the current product only
      /*setRequiredShots((prev) =>
        prev.map((s) =>
          s.shot_type_id === shot.shot_type_id &&
          s.shot_instance === shot.shot_instance
            ? {
                ...s,
                asset_exists: true,
                thumbnail_url: updateResponse.thumbnail_url,
              }
            : s
        )
      );*/
      setRequiredShots((prev) =>
        prev.map((s) =>
          s.shot_type_id === shot.shot_type_id &&
          s.shot_instance === shot.shot_instance
            ? {
                ...s,
                asset_exists: true,
                thumbnail_url: updateResponse.thumbnail_url,
              }
            : s
        )
      );

      setShotUploadStatus((prev) => ({ ...prev, [shotKey]: 'uploaded' }));
      setDialog({
        open: true,
        message: `Successfully uploaded file for shot: ${shot.name}.`,
      });
    } catch (error) {
      setDialog({
        open: true,
        message: `An error occurred while uploading the file for shot: ${shot.name}.`,
      });
    } finally {
      setUploadingShots((prev) => ({ ...prev, [shotKey]: false }));
    }
  };
  ////////
  ////

  ////
  ////////
  const handleBeginBulkUpload = async () => {
    for (const match of uploadResults.matchedShots) {
      const shotKey = `${match.product.product_code}-${match.shot.shot_type_id}-${match.shot.shot_instance}`;

      try {
        // Mark as in progress
        setUploadingShots((prev) => ({ ...prev, [shotKey]: true }));

        // Extract the file path
        const filePath = match.file;
        const file = selectedFiles.rawFiles.find(
          (f) => f.webkitRelativePath === filePath
        );

        if (!file) {
          console.error(`File not found: ${filePath}`);
          setUploadStatus((prev) => ({ ...prev, [shotKey]: 'failed' }));
          continue;
        }

        const payload = {
          customer_id: job.customer_id,
          job_id: job.job_id,
          product_id: String(match.product.product_id),
          workflow_id: job.workflow_id,
          step_id: step.step_id,
          shot_type_id: match.shot.shot_type_id,
          shot_instance: match.shot.shot_instance,
          files: [{ filename: file.name,  mime_type: 'image/jpeg' }],//mime_type: file.type }],
        };

        // Log the payload to the console
        console.log(
          'Payload for signed URL generation:',
          JSON.stringify(payload, null, 2)
        );

        const signedUrlResponse = await getSignedUrls(
          job.customer_id,
          job.job_id,
          String(match.product.product_id),
          payload.files,
          `${match.shot.shot_type_id}-${match.shot.shot_instance}`
        );

        if (!signedUrlResponse?.urls?.length) {
          console.error(`Failed to generate signed URL for file: ${file.name}`);
          setUploadStatus((prev) => ({ ...prev, [shotKey]: 'failed' }));
          continue;
        }

        const { signed_url: signedUrl, storage_path: storagePath } =
          signedUrlResponse.urls[0];

        // Upload from desktop to firebase
        const uploadResponse = await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'image/jpeg' },
          body: file,
        });

        if (!uploadResponse.ok) {
          console.error(`File upload failed for file: ${file.name}`);
          setUploadStatus((prev) => ({ ...prev, [shotKey]: 'failed' }));
          continue;
        }

        const assets = [
          {
            filename: file.name,
            storage_path: storagePath,
            shot_id: `${match.shot.shot_type_id}-${match.shot.shot_instance}`,
          },
        ];

        const updateResponse = await updateUploadStatus(
          //job.customer_id,
          job.job_id,
          match.product.product_id,
          job.workflow_id,
          step.step_id,
          match.shot.shot_type_id,
          match.shot.shot_instance,
          assets
        );

        if (updateResponse.error) {
          console.error(`Upload status update failed: ${updateResponse.error}`);
          setUploadStatus((prev) => ({ ...prev, [shotKey]: 'failed' }));
          continue;
        }

        // Mark as completed
        setUploadStatus((prev) => ({ ...prev, [shotKey]: 'uploaded' }));
      } catch (error) {
        console.error(`Error uploading file for shot: ${shotKey}`, error);
        setUploadStatus((prev) => ({ ...prev, [shotKey]: 'failed' }));
      } finally {
        setUploadingShots((prev) => ({ ...prev, [shotKey]: false }));
      }
    }
  };

  ////////
  ////

  ////
  ////////
  return (
    <>
      <UadminBaseComponent
        stepTitle="Capture Step"
        fetchStatuses={fetchStatuses}
        updateStatus={updateStatus}
        updateBulkStatus={updateBulkStatus}
        handleIndividualEdit={handleIndividualEdit}
        handleBulkEdit={handleBulkEdit}
        dialogMessages={{
          fetchError: 'Failed to fetch products for Retouch step.',
        }}
      />
      <Dialog open={shotsDialogOpen} onClose={() => setShotsDialogOpen(false)}>
        <DialogTitle>
          Select Shots to Upload for {currentProduct?.product_name}
        </DialogTitle>
        <DialogContent>
          {requiredShots.map((shot) => {
            const shotKey = `${shot.shot_type_id}-${shot.shot_instance}`;
            const assetExists =
              shot.asset_exists || shotUploadStatus[shotKey] === 'uploaded';

            return (
              <Paper
                key={shotKey}
                sx={{
                  padding: 2,
                  marginBottom: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {shot.shot_type_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Variation Label: {shot.variation_label || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Optional: {shot.optional ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Comment: {shot.comment || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {uploadingShots[shotKey] ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleShotUpload(shot)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      {assetExists && (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      )}
                    </>
                  )}
                </Box>
                <Box>
                  {shot.thumbnail_url ? (
                    <img
                      src={shot.thumbnail_url}
                      alt={`${shot.shot_type_name} Thumbnail`}
                      style={{ width: 60, height: 60 }}
                    />
                  ) : (
                    <Typography
                      color="text.secondary"
                      style={{
                        width: 60,
                        height: 60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px dashed gray',
                        fontSize: '12px',
                      }}
                    >
                      No Thumbnail
                    </Typography>
                  )}
                </Box>
              </Paper>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShotsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, message: '' })}
      >
        <DialogContent>
          <Typography>{dialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog({ open: false, message: '' })}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={bulkShotsDialogOpen}
        onClose={() => setBulkShotsDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Required Shots for Selected Products</DialogTitle>
        <DialogContent>
          {/* File Selection Button */}
          <Button
            variant="contained"
            component="label"
            color="primary"
            sx={{ marginBottom: 2 }}
          >
            Choose Files
            <input
              type="file"
              webkitdirectory="true" // Enables directory selection
              directory="true"
              multiple
              hidden
              onChange={(e) => {
                const files = Array.from(e.target.files); // Get all selected files
                if (files.length === 0) {
                  console.error('No files selected.');
                  return;
                }

                const directoryPath =
                  files[0]?.webkitRelativePath.split('/')[0] || ''; // Extract root directory
                setSelectedFiles({
                  directory: directoryPath,
                  files: files.map((file) => file.webkitRelativePath),
                  rawFiles: files, // Store the actual `File` objects
                });

                initializeFileOptionsDialog();

                // After files are selected, open the file matching options dialog
                setFileOptionsDialogOpen(true);
              }}
            />
          </Button>

          {/* Product and Shot Details */}
          {bulkRequiredShots.map((product) => (
            <Paper
              key={product.product_name}
              sx={{ padding: 2, marginBottom: 2, border: '1px solid gray' }}
            >
              <Box sx={{ display: 'flex' }}>
                {/* Product Details */}
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {product.product_name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    SKU: {product.product_code}
                  </Typography>
                </Box>

                {/* Shots Details */}
                <Box sx={{ flex: 2 }}>
                  {product.shots.map((shot) => {
                    const shotKey = `${product.product_code}-${shot.shot_type_id}-${shot.shot_instance}`;

                    return (
                      <Paper
                        key={shotKey}
                        sx={{
                          padding: 2,
                          marginBottom: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {shot.shot_type_name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Variation Label: {shot.variation_label || 'N/A'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Optional: {shot.optional ? 'Yes' : 'No'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Comment: {shot.comment || 'N/A'}
                          </Typography>
                        </Box>

                        {/* Upload Progress and Status Icons */}
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          {/* Thumbnail */}
                          {shot.thumbnail_url ? (
                            <img
                              src={shot.thumbnail_url}
                              alt={`${shot.shot_type_name} Thumbnail`}
                              style={{ width: 60, height: 60 }}
                            />
                          ) : (
                            <Typography
                              color="text.secondary"
                              style={{
                                width: 60,
                                height: 60,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px dashed gray',
                                fontSize: '12px',
                              }}
                            >
                              No Thumbnail
                            </Typography>
                          )}

                          {/* Check Icon for Uploaded Shots */}
                          {shot.asset_exists && (
                            <CheckCircleIcon style={{ color: 'green' }} />
                          )}

                          {/* Upload Progress Icon */}
                          {uploadingShots[shotKey] ? (
                            <CircularProgress size={16} />
                          ) : uploadStatus[shotKey] === 'uploaded' ? (
                            <CheckCircleIcon style={{ color: 'green' }} />
                          ) : null}
                        </Box>
                      </Paper>
                    );
                  })}
                </Box>
              </Box>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkShotsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={fileOptionsDialogOpen}
        onClose={() => setFileOptionsDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 4, padding: 3 }, // Rounded corners and padding for modern look
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', pb: 1 }}>
          File Matching Options
        </DialogTitle>

        <DialogContent>
          {/* Folder Assignment Section */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h6" gutterBottom>
              Folder Assignment
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
              <FormControl>
                <RadioGroup
                  value={folderAssignment}
                  onChange={(e) => setFolderAssignment(e.target.value)}
                >
                  <FormControlLabel
                    value="name"
                    control={<Radio />}
                    label="Subfolder is product name"
                    disabled
                  />
                  <FormControlLabel
                    value="code"
                    control={<Radio />}
                    label="Subfolder is product code"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="No Subfolders" // New option
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          {/* File Assignment Section */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h6" gutterBottom>
              File Assignment
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
              <FormControl>
                <RadioGroup
                  value={fileAssignment}
                  onChange={(e) => setFileAssignment(e.target.value)}
                >
                  <FormControlLabel
                    value="sequence"
                    control={<Radio />}
                    label="In Sequence"
                    disabled
                  />
                  <FormControlLabel
                    value="pattern"
                    control={<Radio />}
                    label="Pattern"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          {/* Shot-Suffix Mapping Table */}
          {fileAssignment === 'pattern' && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Shot-Suffix Mapping
              </Typography>

              {/* Add Debug Logs Here */}
              {console.log('Rendering Shot-Suffix Mapping Table:')}
              {console.log('shotSuffixList:', shotSuffixList)}

              {shotSuffixList.length > 0 ? (
                <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Shot</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Suffix
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shotSuffixList.map((entry, index) => (
                        <TableRow key={index}>
                          <TableCell>{entry.shot}</TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={entry.suffix}
                              onChange={(e) => {
                                const updatedList = [...shotSuffixList];
                                updatedList[index].suffix = e.target.value;
                                setShotSuffixList(updatedList);
                              }}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography color="text.secondary">
                  No shot instances available. Please check your selection.
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const results = await handleFileMatching();
              setUploadResults(results); // Store the results
              setFileOptionsDialogOpen(false); // Close the file options dialog
              setUploadResultsDialogOpen(true); // Open the results dialog
            }}
          >
            Begin Upload
          </Button>

          <Button
            onClick={() => setFileOptionsDialogOpen(false)}
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={uploadResultsDialogOpen}
        onClose={() => setUploadResultsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>File Matching Results</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Summary
          </Typography>
          <Typography>
            Matched Shot Instances: {uploadResults.matchedCount}
          </Typography>
          <Typography>
            Unmatched Shot Instances: {uploadResults.unmatchedShotCount}
          </Typography>
          <Typography>
            Unmatched Files: {uploadResults.unmatchedFileCount}
          </Typography>

          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h6" gutterBottom>
              Matched Files
            </Typography>
            {uploadResults.matchedShots?.length > 0 ? (
              <ul>
                {uploadResults.matchedShots.map((match, index) => (
                  <li key={index}>
                    Product: {match.product.product_name} - Shot:{' '}
                    {match.shot.shot_type_name} Instance{' '}
                    {match.shot.shot_instance} - File: {match.file}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No files matched.</Typography>
            )}
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h6" gutterBottom>
              Unmatched Shot Instances
            </Typography>
            {uploadResults.unmatchedShots?.length > 0 ? (
              <ul>
                {uploadResults.unmatchedShots.map((unmatch, index) => (
                  <li key={index}>
                    Product: {unmatch.product.product_name} - Shot:{' '}
                    {unmatch.shot.shot_type_name} Instance{' '}
                    {unmatch.shot.shot_instance}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>All shot instances matched.</Typography>
            )}
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h6" gutterBottom>
              Unmatched Files
            </Typography>
            {uploadResults.unmatchedFiles?.length > 0 ? (
              <ul>
                {uploadResults.unmatchedFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            ) : (
              <Typography>All files matched.</Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUploadResultsDialogOpen(false)}
            sx={{ color: 'text.secondary' }}
          >
            Cancel Upload
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setUploadResultsDialogOpen(false); // Close this dialog
              handleBeginBulkUpload(); // Start the upload process
            }}
          >
            Begin Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CaptureUadminComponent;
