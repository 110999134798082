import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Stack,
  Typography,
  Button,
  IconButton,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import JobDetailDialog from './JobDetailDialog';
import StudioVaultDialog from './StudioVaultDialog';
import StepDetails from './StepDetails';
import api from '../api';
import { deleteJob } from '../api';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useUser } from '../context/UserContext';
 
function JobList({ isAdmin, selectedJob, onVaultOpen, onCloseDialog }) {
  const [jobList, setJobList] = useState([]);
  //const [selectedJob, setSelectedJob] = useState(null);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [openStudioVaultDialog, setOpenStudioVaultDialog] = useState(false);
  const [vaultSource, setVaultSource] = useState('');
  const [selectedStep, setSelectedStep] = useState(null);
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openNewJobDialog, setOpenNewJobDialog] = useState(false);
  const [workflowTemplates, setWorkflowTemplates] = useState([]);
  const [newJob, setNewJob] = useState({
    job_code: '',
    job_name: '',
    deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    description: '',
    high_priority: false,
    workflow_name: '',
  });

  const { userRole } = useUser();

  const statusIcons = {
    complete: <CheckCircleIcon color="success" />,
    inProgress: <HourglassEmptyIcon color="warning" />,
    default: <RadioButtonUncheckedIcon color="disabled" />,
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        //const customerId = 1;
        const jobs = await api.getJobs();
        setJobList(jobs.map((job) => job.job_details));
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    };

    const fetchWorkflowTemplates = async () => {
      try {
        const templates = await api.getWorkflowTemplates();
        setWorkflowTemplates(templates);
      } catch (error) {
        console.error('Failed to fetch workflow templates:', error);
      }
    };

    fetchJobs();
    fetchWorkflowTemplates();
  }, []);

  const filteredJobList = selectedJob
    ? jobList.filter((job) => job.job_id === selectedJob.job_id)
    : jobList;

  const handleNewJobChange = (field, value) => {
    setNewJob((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleDeleteJob = async () => {
    if (!deleteJobId) return;

    setIsDeleting(true);
    try {
      await deleteJob(deleteJobId);
      setJobList((prev) => prev.filter((job) => job.job_id !== deleteJobId));
      alert('Job deleted successfully.');

      // Close the dialog and return to JobOverview
      onCloseDialog();
    } catch (error) {
      console.error('Error deleting job:', error);
      alert('Failed to delete job. Check the console for more details.');
    } finally {
      setIsDeleting(false);
      setDeleteJobId(null);
    }
  };

  /*const handleCreateJob = async () => {
    if (!newJob.job_name || !newJob.deadline || !newJob.workflow_name) {
      alert('Please fill all required fields.');
      return;
    }

    try {
      await api.createJob(newJob);
      setOpenNewJobDialog(false);
      const updatedJobs = await api.getJobs(1);
      setJobList(updatedJobs.map((job) => job.job_details));
    } catch (error) {
      console.error('Error creating job:', error);
      alert('Failed to create job. Check console for more details.');
    }
  };*/

  const handleRowClick = (job) => {
    //setSelectedJob(job);
    //setOpenJobDialog(true);
    // No action needed for row clicks
  };

  const handleStepClick = (e, step, job) => {
    e.stopPropagation();
    setSelectedStep(step);
    //setSelectedJob(job);
  };

  const getStatusIcon = (step) => {
    if (step.progress.completed === 0) {
      return <RadioButtonUncheckedIcon color="disabled" />;
    }
    if (step.progress.completed < step.progress.total) {
      return <HourglassEmptyIcon color="warning" />;
    }
    return <CheckCircleIcon color="success" />;
  };

  const formatDeadline = (deadline) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(deadline).toLocaleDateString(undefined, options);
  };

  const isStepInactive = (workflow, index) => {
    for (let i = 0; i < index; i++) {
      if (workflow[i].progress.completed === 0) {
        return true;
      }
    }
    return false;
  };

  const handleStudioVaultOpen = (source, job) => {
    //setSelectedJob(job);
    setVaultSource(source);
    setOpenStudioVaultDialog(true);
  };

  return (
    <Box sx={{ maxHeight: '67vh', overflowY: 'auto', padding: '10px' }}>
      {filteredJobList.map((job) => (
        <Paper
          key={job.job_id}
          onClick={() => handleRowClick(job)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 2,
            padding: 2,
            boxShadow: 3,
            cursor: 'pointer',
            borderLeft: job.high_priority ? '5px solid #ff6f61' : 'none',
            '&:hover': { backgroundColor: 'action.hover', boxShadow: 6 },
            transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
              <Typography variant="h6" noWrap sx={{ fontWeight: 'bold' }}>
                {job.job_name}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Deadline: {formatDeadline(job.deadline)}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.disabled', fontStyle: 'italic' }}
                noWrap
              >
                {job.description || 'No description available'}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="center">
              <Tooltip title="Open StudioVault" arrow>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStudioVaultOpen('JobList', job);
                  }}
                  sx={{
                    '&:hover': { backgroundColor: 'rgba(0, 0, 255, 0.08)' },
                  }}
                >
                  <PhotoLibraryIcon />
                </IconButton>
              </Tooltip>
              {userRole === 'admin' && (
                <Tooltip title="Delete Job" arrow>
                  <IconButton
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteJobId(job.job_id);
                    }}
                    disabled={isDeleting}
                    sx={{
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
          <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
            {job.workflow.map((step, index) => {
              const inactive = isStepInactive(job.workflow, index);
              return (
                <Paper
                  key={index}
                  elevation={1}
                  onClick={(e) => !inactive && handleStepClick(e, step, job)}
                  sx={{
                    minWidth: '100px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 1,
                    opacity: inactive ? 0.5 : 1,
                    backgroundColor: inactive ? 'rgba(0,0,0,0.1)' : 'inherit',
                    cursor: inactive ? 'not-allowed' : 'pointer',
                    '&:hover': {
                      backgroundColor: inactive ? 'inherit' : 'action.hover',
                      boxShadow: inactive ? 0 : 4,
                    },
                    transition:
                      'background-color 0.2s ease, box-shadow 0.2s ease',
                  }}
                >
                  {getStatusIcon(step)}
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {step.step_name}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {step.progress.completed}/{step.progress.total}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={
                      step.progress.total > 0
                        ? (step.progress.completed / step.progress.total) * 100
                        : 0
                    }
                    sx={{
                      width: '100%',
                      height: 5,
                      marginTop: 1,
                      borderRadius: 2,
                    }}
                  />
                </Paper>
              );
            })}
          </Stack>
        </Paper>
      ))}

      <Dialog open={Boolean(deleteJobId)} onClose={() => setDeleteJobId(null)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this job? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteJobId(null)} disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteJob}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {selectedJob && (
        <JobDetailDialog
          open={openJobDialog}
          onClose={() => setOpenJobDialog(false)}
          job={selectedJob}
        />
      )}
      {openStudioVaultDialog && (
        <StudioVaultDialog
          open={openStudioVaultDialog}
          onClose={() => setOpenStudioVaultDialog(false)}
          customerId={selectedJob?.customer_id}
          jobId={selectedJob?.job_id}
          productId={null}
          source={vaultSource}
        />
      )}
      {selectedStep && selectedJob && (
        <Dialog
          open={!!selectedStep}
          onClose={() => setSelectedStep(null)}
          PaperProps={{
            sx: {
              width: 1200, // Set your desired width in pixels
              maxWidth: '90%', // Optional: Makes it responsive
            },
          }}
        >
          <DialogContent>
            <StepDetails step={selectedStep} job={selectedJob} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default JobList;
