import React, { useState, useMemo, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SchemaIcon from '@mui/icons-material/Schema';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Login from './components/Login';
import AdminMainPage from './components/AdminMainPage';
import CustomerMainPage from './components/CustomerMainPage';
import StudioVaultMainPage from './components/StudioVaultMainPage';
import { lightTheme, darkTheme, brandedTheme } from './theme';
import ErrorBoundary from './components/ErrorBoundary';
import { ListItemIcon } from '@mui/material';
import { Tooltip } from '@mui/material';
import { CustomerProvider } from './context/CustomerContext';
import { UserProvider } from './context/UserContext';

function App() {
  const [themeMode, setThemeMode] = useState('light');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [user, setUser] = useState(null);

  const theme = useMemo(() => {
    const themes = {
      light: lightTheme,
      dark: darkTheme,
      branded: brandedTheme,
    };
    return createTheme(themes[themeMode] || lightTheme);
  }, [themeMode]);

  const navigate = useNavigate();

  useEffect(() => {
    // Listen to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const toggleTheme = () => {
    setThemeMode((prevMode) =>
      prevMode === 'light' ? 'dark' : prevMode === 'dark' ? 'branded' : 'light'
    );
  };

  const handleMenuClose = () => setMenuAnchor(null);

  const handleDialogToggle = (setDialogState) => () => {
    setDialogState((prev) => !prev);
    handleMenuClose();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear user state
      navigate('/');
    } catch (error) {
      console.error('Logout failed: ', error);
    }
    handleMenuClose();
  };

  const themeSwitchLabel =
    themeMode === 'light' ? 'Dark' : themeMode === 'dark' ? 'Branded' : 'Light';

  return (
    <CustomerProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div
            style={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  StudioFlow
                </Typography>
                {user && window.location.pathname === '/' ? (
                  <Tooltip title="Go to StudioVault">
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => navigate('/studiovault')}
                    >
                      <PhotoLibraryIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  user && (
                    <Tooltip title="Go to StudioFlow">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => navigate('/')}
                      >
                        <SchemaIcon />
                      </IconButton>
                    </Tooltip>
                  )
                )}
              </Toolbar>
            </AppBar>

            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              {window.location.pathname === '/' && (
                <MenuItem
                  onClick={() => {
                    navigate('/studiovault');
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <PhotoLibraryIcon />
                  </ListItemIcon>
                  Go to StudioVault
                </MenuItem>
              )}
              {window.location.pathname === '/studiovault' && (
                <MenuItem
                  onClick={() => {
                    navigate('/');
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <SchemaIcon />
                  </ListItemIcon>
                  Go to StudioFlow
                </MenuItem>
              )}
              <MenuItem onClick={handleDialogToggle(setIsAboutOpen)}>
                About
              </MenuItem>
              <MenuItem onClick={handleDialogToggle(setIsSettingsOpen)}>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            <Dialog
              open={isAboutOpen}
              onClose={handleDialogToggle(setIsAboutOpen)}
            >
              <DialogTitle>About StudioFlow</DialogTitle>
              <DialogContent>
                <Typography>
                  StudioFlow is an application designed to manage and streamline
                  your studio workflows.
                </Typography>
              </DialogContent>
            </Dialog>

            <Dialog
              open={isSettingsOpen}
              onClose={handleDialogToggle(setIsSettingsOpen)}
            >
              <DialogTitle>Settings</DialogTitle>
              <DialogContent>
                <Button onClick={toggleTheme}>
                  Switch to {themeSwitchLabel} Mode
                </Button>
              </DialogContent>
            </Dialog>

            <ErrorBoundary>
              <Routes>
                {user ? (
                  <>
                    <Route path="/" element={<CustomerMainPage />} />
                    <Route path="/customer" element={<CustomerMainPage />} />
                    <Route path="/admin" element={<AdminMainPage />} />
                    <Route
                      path="/studiovault"
                      element={<StudioVaultMainPage />}
                    />
                  </>
                ) : (
                  <Route path="/" element={<Login />} />
                )}
              </Routes>
            </ErrorBoundary>

            <div style={{ textAlign: 'center', padding: '10px' }}>
              <Typography
                variant="body1"
                onClick={toggleTheme}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '-50px',
                }}
              >
                Switch to {themeSwitchLabel} Mode
              </Typography>
            </div>
          </div>
        </ThemeProvider>
      </UserProvider>
    </CustomerProvider>
  );
}

export default App;
