import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdTokenResult();
          const customerIdClaim = token.claims.customer_id;

          if (!customerIdClaim) {
            console.error('No customer_id found in JWT claims.');
            throw new Error('Customer ID missing in token.');
          }

          //setCustomerId(customerIdClaim);

          setCustomerId(parseInt(customerIdClaim, 10));
        } catch (error) {
          console.error('Error fetching customer_id:', error);
        }
      } else {
        console.error('No authenticated user found.');
      }
      setLoading(false); // Set loading to false whether user is authenticated or not
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  return (
    <CustomerContext.Provider value={{ customerId, loading }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => useContext(CustomerContext);
