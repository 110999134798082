import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';

const UpgradeDialog = ({ open, onClose, onUpgrade }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Unlock Pro Features</DialogTitle>
      <DialogContent>
        <Typography>
          The Advanced features are part of our <strong>Pro version</strong>.
          Upgrade to access enhanced capabilities, including advanced search
          options and more.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" color="primary" onClick={onUpgrade}>
          Upgrade Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
