// frontend/react-app/src/components/steps/JobCreationUadminComponent.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  bulkUploadProducts,
  addProductToJob,
  updateProductStatus,
  updateBulkProductStatus,
  fetchProductStatuses,
  deleteProductFromJob, // Import for delete functionality
  getTemplates, // New import for fetching style templates
  updateProductStyleTemplate, // New import for updating individual templates
  bulkUpdateTemplates, // New import for bulk updating templates
} from '../../api';
import KanbanBoard from '../KanbanBoard';

function JobCreationUadminComponent({ config, job, step }) {
  const { customer_id, job_id, workflow_id } = job || {};
  const { step_id } = step || {};

  const [productData, setProductData] = useState({
    product_code: '',
    product_name: '',
    product_description: '',
  });
  const [products, setProducts] = useState([]);
  const [possibleStatuses, setPossibleStatuses] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState('');
  const [file, setFile] = useState(null);

  const [styleTemplates, setStyleTemplates] = useState([]); // New state for style templates

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // State for managing delete confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    product: null,
    removeFromProducts: false,
  });

  useEffect(() => {
    if (job_id) {
      // Fetch product statuses based on job details
      fetchProductStatuses(job_id, workflow_id, step_id)
        .then((data) => {
          setProducts(data.products);
          setPossibleStatuses(data.possible_statuses);
          setDefaultStatus(data.default_status || 'notStarted');
        })
        .catch((error) => {
          console.error('Error fetching product statuses:', error);
          setSnackbar({
            open: true,
            message: 'Failed to fetch product statuses.',
            severity: 'error',
          });
        });

      getTemplates()
        .then((response) => {
          console.log('Response from getTemplates:', response); // Log the raw response

          // Extract templates array from the response object
          const templates = response.templates;

          if (Array.isArray(templates)) {
            console.log('Templates received:', templates); // Log the templates array
            setStyleTemplates(templates); // Update state with the extracted templates
          } else {
            console.error('Invalid templates format:', response); // Log unexpected format
            setStyleTemplates([]); // Fallback to an empty array
          }
        })
        .catch((error) => {
          console.error('Error fetching style templates:', error); // Log error details
          setSnackbar({
            open: true,
            message: 'Failed to fetch style templates.',
            severity: 'error',
          });
          setStyleTemplates([]); // Fallback to an empty array
        });
    }
  }, [customer_id, job_id, workflow_id, step_id]);
  ///
  ///
  ///
  ///
  const handleAddProduct = async () => {
    const { product_code, product_name, product_description } = productData;

    if (!product_code || !product_name) {
      setSnackbar({
        open: true,
        message: 'Product code and name are required.',
        severity: 'warning',
      });
      return;
    }

    const newProduct = {
      product_code,
      product_name,
      product_description,
      status: defaultStatus,
      template: 'Default', // Optional: Initialize with a default template
    };

    try {
      const response = await addProductToJob(
        job_id,
        workflow_id,
        step_id,
        newProduct
      );
      const addedProducts = response.successes || []; // Handle successes array
      setProducts((prev) => [...prev, ...addedProducts]); // Append new products to state
      setSnackbar({
        open: true,
        message: `${addedProducts.length} product(s) added successfully.`,
        severity: 'success',
      });
      setProductData({
        product_code: '',
        product_name: '',
        product_description: '',
      }); // Clear input fields
    } catch (error) {
      console.error('Error adding product:', error);
      setSnackbar({
        open: true,
        message: 'Failed to add product. Please try again.',
        severity: 'error',
      });
    }
  };
  ///
  ///
  ///
  ///
  const handleIndividualEditTemplate = async (productId, templateId) => {
    try {
      await updateProductStyleTemplate(job_id, productId, templateId);
      setSnackbar({
        open: true,
        message: 'Template updated successfully!',
        severity: 'success',
      });

      setProducts((prev) =>
        prev.map((p) =>
          p.product_id === productId ? { ...p, template: templateId } : p
        )
      );
    } catch (error) {
      console.error('Error updating template:', error);
      setSnackbar({
        open: true,
        message: 'Error updating template.',
        severity: 'error',
      });
    }
  };

  const handleBulkEditTemplate = async (selectedProductIds, templateId) => {
    console.log('handleBulkEditTemplate called with:', {
      customer_id,
      job_id,
      selectedProductIds,
      templateId,
    });

    if (!selectedProductIds.length || typeof templateId !== 'number') {
      console.error(
        'Invalid parameters for bulk edit. Ensure products and template are valid.'
      );
      return;
    }

    try {
      await bulkUpdateTemplates(job_id, selectedProductIds, templateId);

      setSnackbar({
        open: true,
        message: 'Templates updated successfully!',
        severity: 'success',
      });

      setProducts((prev) =>
        prev.map((p) =>
          selectedProductIds.includes(p.product_id)
            ? { ...p, template: templateId }
            : p
        )
      );
    } catch (error) {
      console.error('Error updating templates in bulk:', error);
      setSnackbar({
        open: true,
        message: 'Error updating templates.',
        severity: 'error',
      });
    }
  };

  const handleDragEnd = (productId, newStatus) => {
    console.log(
      `Drag End triggered for productId: ${productId}, newStatus: ${newStatus}`
    );

    const product = products.find((p) => p.product_id === productId);

    if (product && product.status !== newStatus) {
      console.log(`Product found:`, product);
      console.log(`Updating status from ${product.status} to ${newStatus}`);

      const updatedProducts = products.map((p) =>
        p.product_id === productId ? { ...p, status: newStatus } : p
      );
      setProducts(updatedProducts);

      updateProductStatus({
        //customer_id,
        job_id,
        workflow_id,
        step_id,
        product_id: productId,
        new_status: newStatus,
        changed_by: 'current_user',
      })
        .then(() => {
          console.log(
            `Successfully updated product status for productId: ${productId}`
          );
          setSnackbar({
            open: true,
            message: `Product "${product.product_name}" moved to ${newStatus}`,
            severity: 'success',
          });
        })
        .catch((err) => {
          console.error(
            `Error updating product status for productId ${productId}:`,
            err
          );
          setProducts((prevProducts) =>
            prevProducts.map((p) =>
              p.product_id === productId ? { ...p, status: product.status } : p
            )
          );
          setSnackbar({
            open: true,
            message: 'Error updating product status.',
            severity: 'error',
          });
        });
    } else {
      console.warn(
        `Product with productId ${productId} not found or already in status ${newStatus}.`
      );
    }
  };

  const handleBulkMove = (productsToMove, newStatus) => {
    const productIds = productsToMove.map((p) => p.product_id);
    console.log(
      `Bulk move triggered for productIds: ${productIds} to newStatus: ${newStatus}`
    );

    updateBulkProductStatus({
      //customer_id,
      job_id,
      workflow_id,
      step_id,
      product_ids: productIds,
      new_status: newStatus,
      changed_by: 'current_user',
    })
      .then(() => {
        console.log(
          `Successfully updated bulk product statuses for productIds: ${productIds}`
        );
        setProducts((prevProducts) =>
          prevProducts.map((p) =>
            productIds.includes(p.product_id) ? { ...p, status: newStatus } : p
          )
        );
        setSnackbar({
          open: true,
          message: 'Bulk product statuses updated successfully!',
          severity: 'success',
        });
      })
      .catch((err) => {
        console.error('Error updating bulk product statuses:', err);
        setSnackbar({
          open: true,
          message: 'Failed to update bulk product statuses.',
          severity: 'error',
        });
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      console.warn('No file selected for upload.');
      setSnackbar({
        open: true,
        message: 'Please select a file!',
        severity: 'warning',
      });
      return;
    }

    try {
      const text = await file.text();
      const rows = text.split('\n').slice(1).filter(Boolean);
      const productsToUpload = rows.map((row) => {
        const [product_code, product_name, product_description] =
          row.split(',');
        return { product_code, product_name, product_description };
      });

      const { successes } = await bulkUploadProducts(
        job_id,
        workflow_id,
        step_id,
        productsToUpload
      );

      setProducts((prev) => [...prev, ...successes]);
      setSnackbar({
        open: true,
        message: `${successes.length} products uploaded successfully.`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error uploading products:', error);
      setSnackbar({
        open: true,
        message: 'Failed to upload products.',
        severity: 'error',
      });
    }
  };

  // Handler to initiate delete request
  const handleDeleteRequest = (product) => {
    setConfirmDelete({ open: true, product, removeFromProducts: false });
  };

  // Handler to confirm deletion
  const handleDeleteConfirm = async () => {
    try {
      const { product, removeFromProducts } = confirmDelete;
      await deleteProductFromJob(
        job_id,
        product.product_id,
        removeFromProducts
      );
      setSnackbar({
        open: true,
        message: 'Product deleted successfully!',
        severity: 'success',
      });
      setProducts((prev) =>
        prev.filter((p) => p.product_id !== product.product_id)
      );
    } catch (error) {
      console.error(
        'Error deleting product:',
        error.response?.data || error.message
      );
      setSnackbar({
        open: true,
        message: 'Error deleting product.',
        severity: 'error',
      });
    } finally {
      setConfirmDelete({
        open: false,
        product: null,
        removeFromProducts: false,
      });
    }
  };

  // Define the onEdit function

  // ConfirmDialog Component
  function ConfirmDialog({
    open,
    onClose, // Correct prop name
    title,
    content,
    onConfirm,
    checkboxLabel,
    onCheckboxChange,
    checked,
  }) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
          {checkboxLabel && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => onCheckboxChange(e.target.checked)}
                  color="primary"
                />
              }
              label={checkboxLabel}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {' '}
            {/* Use onClose */}
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box sx={{ padding: 3, textAlign: 'center' }}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Job Definition
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        ({products.length} Products)
      </Typography>

      <KanbanBoard
        statuses={possibleStatuses}
        products={products}
        defaultStatus={defaultStatus}
        onDragEnd={(productOrProducts, newStatus) => {
          if (Array.isArray(productOrProducts)) {
            console.log('Bulk move detected:', productOrProducts);
            handleBulkMove(
              products.filter((product) =>
                productOrProducts.includes(product.product_id)
              ),
              newStatus
            );
          } else {
            console.log('Individual move detected:', productOrProducts);
            handleDragEnd(productOrProducts, newStatus);
          }
        }}
        onBulkMove={handleBulkMove}
        onDeleteRequest={handleDeleteRequest} // Passed the delete handler to KanbanBoard
        onIndividualEdit={handleIndividualEditTemplate}
        onBulkEdit={handleBulkEditTemplate}
        customerId={customer_id} // Pass necessary props if needed
        jobId={job_id}
        styleTemplates={styleTemplates} // Pass styleTemplates to KanbanBoard
      />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Add New Product</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Product Code"
                name="product_code"
                value={productData.product_code}
                onChange={(e) =>
                  setProductData((prev) => ({
                    ...prev,
                    product_code: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Product Name"
                name="product_name"
                value={productData.product_name}
                onChange={(e) =>
                  setProductData((prev) => ({
                    ...prev,
                    product_name: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Product Description"
                name="product_description"
                value={productData.product_description}
                onChange={(e) =>
                  setProductData((prev) => ({
                    ...prev,
                    product_description: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              {/*<Button
                variant="contained"
                onClick={() => {
                  const { product_code, product_name, product_description } =
                    productData;
                  if (!product_code || !product_name) {
                    setSnackbar({
                      open: true,
                      message: 'Product code and name are required.',
                      severity: 'warning',
                    });
                    return;
                  }

                  const newProduct = {
                    product_id: Date.now(), // Replace with actual ID generation logic
                    product_code,
                    product_name,
                    product_description,
                    status: defaultStatus,
                    template: 'Default', // Initialize with default template
                  };

                  setProducts((prev) => [...prev, newProduct]);
                  setProductData({
                    product_code: '',
                    product_name: '',
                    product_description: '',
                  });
                  setSnackbar({
                    open: true,
                    message: 'Product added successfully.',
                    severity: 'success',
                  });
                }}
              >*/}
              <Button variant="contained" onClick={handleAddProduct}>
                Add Product
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Upload Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button variant="outlined" component="label" sx={{ mr: 2 }}>
            Choose File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <Button variant="contained" onClick={handleFileUpload}>
            Upload
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Positioning
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirm Dialog for deletion */}
      <ConfirmDialog
        open={confirmDelete.open}
        onClose={() =>
          setConfirmDelete({
            open: false,
            product: null,
            removeFromProducts: false,
          })
        } // Define onClose
        title="Delete Product"
        content="Are you sure you want to delete this product? Optionally, you can remove it from the PRODUCTS table."
        onConfirm={handleDeleteConfirm}
        checkboxLabel="Also delete from PRODUCTS table"
        onCheckboxChange={(checked) =>
          setConfirmDelete((prev) => ({ ...prev, removeFromProducts: checked }))
        }
        checked={confirmDelete.removeFromProducts}
      />
    </Box>
  );
}

export default JobCreationUadminComponent;
