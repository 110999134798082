import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudioVaultDialog from './StudioVaultDialog';
import { getJobDetails } from '../api';

function JobDetailDialog({ open, onClose, job }) {
  const [openStudioVaultDialog, setOpenStudioVaultDialog] = useState(false);
  const [vaultSource, setVaultSource] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [steps, setSteps] = useState([]); // Steps as columns
  const [productsByStep, setProductsByStep] = useState({}); // Products grouped by step
  const [jobTitle, setJobTitle] = useState(job?.job_name || 'No Title');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (job?.job_id) {
      setLoading(true);
      getJobDetails(job.job_id)
        .then((data) => {
          const fetchedProducts = data.job_details.products || [];
          const fetchedSteps = [
            ...new Set(fetchedProducts.flatMap((p) => Object.keys(p.steps))),
          ];

          // Group products by their current step
          const groupedProducts = fetchedSteps.reduce((acc, step) => {
            acc[step] = fetchedProducts.filter(
              (product) => product.steps[step]
            );
            return acc;
          }, {});

          setSteps(fetchedSteps); // Set steps as columns
          setProductsByStep(groupedProducts); // Products grouped under steps
          setJobTitle(data.job_details.job_name || 'No Title');
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching job details:', error);
          setLoading(false);
        });
    }
  }, [job]);

  const handleStudioVaultOpen = (source, product) => {
    setVaultSource(source);
    setSelectedRow(product);
    setOpenStudioVaultDialog(true);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceStep = steps[source.droppableId];
    const destStep = steps[destination.droppableId];

    const sourceItems = Array.from(productsByStep[sourceStep]);
    const [movedItem] = sourceItems.splice(source.index, 1);

    const updatedColumns = { ...productsByStep };

    if (sourceStep === destStep) {
      // Reorder within the same step
      sourceItems.splice(destination.index, 0, movedItem);
      updatedColumns[sourceStep] = sourceItems;
    } else {
      // Move to a different step
      const destItems = Array.from(productsByStep[destStep]);
      destItems.splice(destination.index, 0, movedItem);

      updatedColumns[sourceStep] = sourceItems;
      updatedColumns[destStep] = destItems;
    }

    setProductsByStep(updatedColumns);
  };

  if (!job) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{ height: '90vh', margin: 'auto' }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontSize: '1.5rem',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          {jobTitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          {job.description || 'No description available'}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Deadline: {job.deadline}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <Typography>Loading job details...</Typography>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto' }}>
              {steps.map((step, index) => (
                <Droppable key={step} droppableId={step}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        minWidth: '300px',
                        flex: 1,
                        backgroundColor: 'background.paper',
                        borderRadius: 2,
                        padding: 2,
                        boxShadow: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}
                      >
                        {step}
                      </Typography>
                      {productsByStep[step]?.map((product, productIndex) => (
                        <Draggable
                          key={product.product_id}
                          draggableId={product.product_id.toString()}
                          index={productIndex}
                        >
                          {(provided) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                marginBottom: 2,
                                padding: 2,
                                boxShadow: 3,
                                '&:hover': {
                                  backgroundColor: 'action.hover',
                                  boxShadow: 6,
                                },
                                transition:
                                  'background-color 0.2s ease, box-shadow 0.2s ease',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {product.product_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: 'text.secondary' }}
                                >
                                  SKU: {product.product_code}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: 'text.secondary' }}
                                >
                                  Brand: {product.brand}
                                </Typography>
                              </Box>
                              <Tooltip title="Go to StudioVault" arrow>
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleStudioVaultOpen(
                                      'JobDetailDialog',
                                      product
                                    )
                                  }
                                >
                                  <PhotoLibraryIcon />
                                </IconButton>
                              </Tooltip>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              ))}
            </Box>
          </DragDropContext>
        )}
      </DialogContent>

      {openStudioVaultDialog && (
        <StudioVaultDialog
          open={openStudioVaultDialog}
          onClose={() => setOpenStudioVaultDialog(false)}
          customerId={job.customer_id}
          jobId={job.job_id}
          productId={selectedRow?.product_id || null}
          workflowId={job.workflow_id}
          source={vaultSource}
        />
      )}
    </Dialog>
  );
}

export default JobDetailDialog;
