// Option 2: Update stepComponents.js to match the existing file names with 'U'

import JobCreationUadminComponent from './steps/JobCreationUadminComponent';
import JobCreationUcustomerComponent from './steps/JobCreationUcustomerComponent';
import ProductionCalendarUadminComponent from './steps/ProductionCalendarUadminComponent';
import ProductionCalendarUcustomerComponent from './steps/ProductionCalendarUcustomerComponent';
import SampleCheckInUadminComponent from './steps/SampleCheckInUadminComponent';
import SampleCheckInUcustomerComponent from './steps/SampleCheckInUcustomerComponent';
import StylingUadminComponent from './steps/StylingUadminComponent';
import StylingUcustomerComponent from './steps/StylingUcustomerComponent';
import CaptureUadminComponent from './steps/CaptureUadminComponent';
import CaptureUcustomerComponent from './steps/CaptureUcustomerComponent';
import RetouchUadminComponent from './steps/RetouchUadminComponent';
import RetouchUcustomerComponent from './steps/RetouchUcustomerComponent';
import QAInternalUadminComponent from './steps/QaInternalUadminComponent';
import QAInternalUcustomerComponent from './steps/QaInternalUcustomerComponent';
import ApprovalUadminComponent from './steps/ApprovalUadminComponent';
import ApprovalUcustomerComponent from './steps/ApprovalUcustomerComponent';
import PublishUadminComponent from './steps/PublishUadminComponent';
import PublishUcustomerComponent from './steps/PublishUcustomerComponent';
import SampleCheckOutUadminComponent from './steps/SampleCheckOutUadminComponent';
import SampleCheckOutUcustomerComponent from './steps/SampleCheckOutUcustomerComponent';
// Import additional step components as needed

const stepComponents = {
  job_creation_admin: JobCreationUadminComponent,
  job_creation_customer: JobCreationUcustomerComponent,
  production_calendar_admin: ProductionCalendarUadminComponent,
  production_calendar_customer: ProductionCalendarUcustomerComponent,
  sample_check_in_admin: SampleCheckInUadminComponent,
  sample_check_in_customer: SampleCheckInUcustomerComponent,
  styling_admin: StylingUadminComponent,
  styling_customer: StylingUcustomerComponent,
  capture_admin: CaptureUadminComponent,
  capture_customer: CaptureUcustomerComponent,
  retouch_admin: RetouchUadminComponent,
  retouch_customer: RetouchUcustomerComponent,
  qa_internal_admin: QAInternalUadminComponent,
  qa_internal_customer: QAInternalUcustomerComponent,
  approval_admin: ApprovalUadminComponent,
  approval_customer: ApprovalUcustomerComponent,
  publish_admin: PublishUadminComponent,
  publish_customer: PublishUcustomerComponent,
  sample_check_out_admin: SampleCheckOutUadminComponent,
  sample_check_out_customer: SampleCheckOutUcustomerComponent,
  // Add more mappings here
};

export default stepComponents;
