// src/api.js
import axios from 'axios';
import { getAuth } from 'firebase/auth';

//const API_BASE_URL = 'http://localhost:3000/api';
const API_BASE_URL = 'https://studioflow-backend-254627856323.africa-south1.run.app/api';


// getjobs
/*export const getJobs = async () => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.get(`${API_BASE_URL}/jobs/${customerId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};*/
export const getJobs = async () => {
  try {
    //const customerId = await getCustomerIdFromJWT();
    const customerId = parseInt(await getCustomerIdFromJWT(), 10);

    // Log the value and type of customerId
    console.log('Customer ID:', customerId, 'Type:', typeof customerId);

    const response = await axios.get(`${API_BASE_URL}/jobs/${customerId}`);

    // Check if the response contains any jobs
    if (!response.data || response.data.length === 0) {
      console.warn('No jobs found for this customer.');
      return { message: 'No jobs found for this customer.', jobs: [] };
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

// Fetch job details for a specific job and customer
export const getJobDetails = async (jobId) => {
  try {
    const customerId = parseInt(await getCustomerIdFromJWT(), 10);
    const response = await axios.get(
      `${API_BASE_URL}/job-details/${customerId}/${jobId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching job details:', error);
    throw error;
  }
};

// Fetch customer details for a specific customer ID
/*
export const getCustomerDetails = async () => {
  try {
    let customerId = parseInt(await getCustomerIdFromJWT(), 10);
    const response = await axios.get(`${API_BASE_URL}/customers/${customerId}`, {
      headers: { Authorization: `Bearer ${token}` }, // Add Authorization header
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer details:', error);
    throw error;
  }
};
*/
export const getCustomerDetails = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error('No authenticated user found.');
      throw new Error('Authentication required.');
    }

    // Fetch the token explicitly in this function
    const token = await currentUser.getIdToken(); // Fetch token from currentUser
    console.log('Retrieved Token:', token); // Debug log to confirm token is fetched

    const customerId = parseInt(await getCustomerIdFromJWT(), 10);
    const response = await axios.get(
      `${API_BASE_URL}/customers/${customerId}`,
      {
        headers: { Authorization: `Bearer ${token}` }, // Use the token here
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching customer details:', error);
    throw error;
  }
};



// API for fetching assets based from studiovault based on many selection criteria
export const fetchProducts = async (queryParams) => {
  try {
    const customerId = parseInt(await getCustomerIdFromJWT(), 10);
    console.log(
      'API URL:',
      `${API_BASE_URL}/studiovault/customers/${customerId}/products?${queryParams}`
    );
    const response = await axios.get(
      `${API_BASE_URL}/studiovault/customers/${customerId}/products?${queryParams}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

// getWorkflowStepDetails
export const getWorkflowStepDetails = async (jobId, workflowId, stepId) => {
  try {
    const customerId = parseInt(await getCustomerIdFromJWT(), 10);
    const url = `${API_BASE_URL}/workflows/customers/${customerId}/jobs/${jobId}/workflows/${workflowId}/steps/${stepId}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching workflow step details:', error);
    throw error;
  }
};

// Fetch workflow templates
export const getWorkflowTemplates = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/workflow_templates`);
    return response.data;
  } catch (error) {
    console.error('Error fetching workflow templates:', error);
    throw error;
  }
};

// Helper to get customer_id from JWT
const getCustomerIdFromJWT = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error('No authenticated user found.');
      throw new Error('Authentication required.');
    }

    // Decode the Firebase JWT to get claims
    const token = await currentUser.getIdTokenResult();
    console.log('Decoded Firebase JWT:', token);

    const customerId = token.claims.customer_id; // Expecting 'customer_id' in custom claims
    if (!customerId) {
      console.error('No customer_id claim found in token.');
      throw new Error('Customer ID missing in token.');
    }

    console.log('Extracted customer_id from token:', customerId);
    return customerId;
  } catch (error) {
    console.error('Error extracting customer_id from JWT:', error);
    throw error;
  }
};

// API call to create a job
export const createJob = async (jobData) => {
  try {
    const customerId = await getCustomerIdFromJWT(); // Retrieve customer_id
    const token = await getAuth().currentUser.getIdToken(); // Get JWT for Authorization

    const response = await axios.post(
      `${API_BASE_URL}/jobs`,
      { ...jobData, customer_id: customerId }, // Add customer_id to request payload
      {
        headers: { Authorization: `Bearer ${token}` }, // Pass token in headers
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};

// API to delete a job
export const deleteJob = async (jobId) => {
  try {
    const customerId = await getCustomerIdFromJWT(); // Use the existing JWT decoding logic
    const token = await getAuth().currentUser.getIdToken(); // Get JWT for Authorization

    const response = await axios.delete(`${API_BASE_URL}/jobs/${jobId}`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { customer_id: customerId }, // Send customer_id in the request body
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting job:', error);
    throw error;
  }
};

// Create a new product in a job
export const addProductToJob = async (
  jobId,
  workflow_id,
  step_id,
  productData
) => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.post(
      `${API_BASE_URL}/customers/${customerId}/jobs/${jobId}/products`,
      {
        ...productData,
        workflow_id,
        step_id,
      }
    );
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding product to job:', error);
    throw error.response?.data || error;
  }
};

// Create Products for job BULK
// Bulk upload products to a job
export const bulkUploadProducts = async (
  jobId,
  workflowId,
  stepId,
  products
) => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.post(
      `${API_BASE_URL}/customers/${customerId}/jobs/${jobId}/products/bulk`,
      {
        workflow_id: workflowId,
        step_id: stepId,
        products,
      }
    );
    return response.data; // Return the aggregated success and failure results
  } catch (error) {
    console.error('Error uploading products in bulk:', error);
    throw error.response?.data || error; // Pass the error to the calling function
  }
};

// Get a list of style templates
export const getTemplates = async () => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.get(`${API_BASE_URL}/templates/${customerId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching templates:', error);
    throw error;
  }
};

// Update a products style template
export const updateProductStyleTemplate = async (
  jobId,
  productId,
  templateId
) => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.put(
      `${API_BASE_URL}/jobs/${customerId}/${jobId}/products/${productId}/template`,
      { template_id: templateId }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating product template:', error);
    throw error;
  }
};

// Update a bulk set of product style guides
export const bulkUpdateTemplates = async (jobId, productIds, templateId) => {
  console.log(
    `bulkUpdateTemplates called with: jobId=${jobId}, productIds=${productIds}, templateId=${templateId}`
  );

  // Validate input
  if (!Array.isArray(productIds) || typeof templateId !== 'number') {
    console.error('Invalid input to bulkUpdateTemplates:', {
      jobId,
      productIds,
      templateId,
    });
    throw new Error(
      'Invalid parameters. Ensure productIds is an array and templateId is a number.'
    );
  }

  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.put(
      `${API_BASE_URL}/jobs/${customerId}/${jobId}/templates`,
      {
        template_id: templateId, // The template to apply
        product_ids: productIds, // List of product IDs to update
      }
    );

    console.log('API response for bulkUpdateTemplates:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in bulkUpdateTemplates API call:', {
      message: error.message,
      response: error.response?.data,
    });
    throw error;
  }
};

// Delete a product from a job
export const deleteProductFromJob = async (
  jobId,
  productId,
  removeFromProducts = false
) => {
  try {
    const customerId = await getCustomerIdFromJWT();
    const response = await axios.delete(
      `${API_BASE_URL}/customers/${customerId}/jobs/${jobId}/products/${productId}`,
      { params: { removeFromProducts } }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting product from job:', error);
    throw error.response?.data || error;
  }
};

// API for Updating Single Product Status
// API for Updating Single Product Status
export const updateProductStatus = async (data) => {
  console.log('[API] updateProductStatus called with data:', data);

  try {
    console.log('[API] Retrieving customerId...');
    const customerId = await getCustomerIdFromJWT();
    console.log('[API] customerId retrieved:', customerId);

    // Add customerId to the payload
    const payload = {
      ...data,
      customer_id: customerId,
    };
    console.log('[API] Final payload to be sent:', payload);

    console.log('[API] Sending POST request to update product status...');
    const response = await axios.post(
      `${API_BASE_URL}/update-product-status`,
      payload
    );
    console.log('[API] POST request successful. Response:', response.data);

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('[API] Error Response from Server:', {
        status: error.response.status,
        headers: error.response.headers,
        data: error.response.data,
      });
    } else if (error.request) {
      console.error('[API] No response received from server:', error.request);
    } else {
      console.error('[API] Error in setup:', error.message);
    }

    console.error('[API] Full Error Object:', error);
    throw error;
  } finally {
    console.log('[API] updateProductStatus operation completed.');
  }
};

// API for Bulk Product Status Update
export const updateBulkProductStatus = async (data) => {
  console.log('[API] updateBulkProductStatus called with data:', data);

  try {
    console.log('[API] Retrieving customerId...');
    const customerId = await getCustomerIdFromJWT();
    console.log('[API] customerId retrieved:', customerId);

    // Add customerId to the payload
    const payload = {
      ...data,
      customer_id: customerId,
    };
    console.log('[API] Final payload to be sent:', payload);

    console.log(
      '[API] Sending POST request to update bulk product statuses...'
    );
    const response = await axios.post(
      `${API_BASE_URL}/update-bulk-product-status`,
      payload
    );
    console.log('[API] POST request successful. Response:', response.data);

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('[API] Error Response from Server:', {
        status: error.response.status,
        headers: error.response.headers,
        data: error.response.data,
      });
    } else if (error.request) {
      console.error('[API] No response received from server:', error.request);
    } else {
      console.error('[API] Error in setup:', error.message);
    }

    console.error('[API] Full Error Object:', error);
    throw error;
  } finally {
    console.log('[API] updateBulkProductStatus operation completed.');
  }
};

// Fetch products and thier status for a step
export const fetchProductStatuses = async (job_id, workflow_id, step_id) => {
  try {
    const customer_id = parseInt(await getCustomerIdFromJWT(), 10);
    const response = await axios.get(`${API_BASE_URL}/get-product-status`, {
      params: { customer_id, job_id, workflow_id, step_id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product statuses:', error);
    throw error;
  }
};

// Get Required Shots API
export const getRequiredShots = async (jobId, productIds, workflowId) => {
  try {
    let customerId = parseInt(await getCustomerIdFromJWT(), 10);
    const response = await axios.get(
      `${API_BASE_URL}/customers/${customerId}/jobs/${jobId}/products/shots`,
      {
        params: { product_ids: productIds.join(','), workflow_id: workflowId },
      }
    );
    console.log('getRequiredShots Response:', {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: response.data,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching required shots:', error);
    throw error;
  }
};

export const getSignedUrls = async (customerId, jobId, productId, files) => {
  //const customerId = parseInt(await getCustomerIdFromJWT(), 10);
  try {
    console.log('[Central API] Requesting signed URLs with payload:', {
      customer_id: customerId,
      job_id: jobId,
      product_id: productId,
      files,
    });
    const response = await axios.post(`${API_BASE_URL}/storage/signed-urls`, {
      customer_id: customerId,
      job_id: jobId,
      product_id: productId,
      files,
    });
    console.log('[Central API] Received signed URLs:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      '[Central API] Error fetching signed URLs:',
      error.response?.data || error.message
    );
    throw error;
  }
};

export const updateUploadStatus = async (
  jobId,
  productId,
  workflowId,
  step_id,
  shot_type_id,
  shot_instance,
  assets
) => {
  const customerId = await getCustomerIdFromJWT();
  try {
    console.log('[Central API] Request Payload:', {
      customerId: customerId,
      jobId: jobId,
      productId: productId,
      workflow_id: workflowId,
      step_id: step_id,
      shot_type_id: shot_type_id,
      shot_instance: shot_instance,
      assets,
    });
    const response = await axios.post(
      `${API_BASE_URL}/customers/${customerId}/jobs/${jobId}/products/${productId}/shots/assets`,
      {
        workflow_id: workflowId,
        step_id: step_id,
        shot_type_id: shot_type_id,
        shot_instance: shot_instance,
        assets,
      }
    );
    console.log('[Central API] Upload status updated:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      '[Central API] Error updating upload status:',
      error.response?.data || error.message
    );
    throw error;
  }
};

export default {
  getJobs,
  getJobDetails,
  getCustomerDetails,
  fetchProducts,
  getWorkflowStepDetails,
  getWorkflowTemplates,
  createJob,
  addProductToJob,
  bulkUploadProducts,
  getTemplates,
  updateProductStyleTemplate,
  bulkUpdateTemplates,
  deleteProductFromJob,
  fetchProductStatuses,
  updateProductStatus,
  updateBulkProductStatus,
  updateUploadStatus,
  getSignedUrls,
  getRequiredShots,
};
