import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  LinearProgress,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import api from '../api';
import { useUser } from '../context/UserContext';
 
function JobOverview({ onJobSelect }) {
  const [jobList, setJobList] = useState([]);
  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [openNewJobDialog, setOpenNewJobDialog] = useState(false);
  const [workflowTemplates, setWorkflowTemplates] = useState([]);
  const [newJob, setNewJob] = useState({
    job_code: '',
    job_name: '',
    deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    description: '',
    high_priority: false,
    workflow_name: '',
  });

  const { userRole } = useUser();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobs = await api.getJobs();
        setJobList(jobs.map((job) => job.job_details));
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    };

    fetchJobs();
  }, []); // Fetch jobs once on component mount

  useEffect(() => {
    const fetchWorkflowTemplates = async () => {
      try {
        const templates = await api.getWorkflowTemplates();
        console.log('Fetched workflow templates:', templates);
        setWorkflowTemplates(templates);
      } catch (error) {
        console.error('Failed to fetch workflow templates:', error);
      }
    };

    fetchWorkflowTemplates();
  }, []); // Fetch workflow templates once on component mount

  const handleNewJobChange = (field, value) => {
    setNewJob((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleCreateJob = async () => {
    if (!newJob.job_name || !newJob.deadline || !newJob.workflow_name) {
      alert('Please fill all required fields.');
      return;
    }

    try {
      // Send the new job to the server
      const createdJob = await api.createJob(newJob);

      // Update the local job list with the newly created job
      setJobList((prevJobs) => [
        {
          ...createdJob, // Use the response from the server
          workflow: createdJob.workflow || [], // Ensure workflow is initialized
          high_priority: createdJob.high_priority || false, // Ensure priority is set
        },
        ...prevJobs, // Keep existing jobs
      ]);

      // Close the dialog
      setOpenNewJobDialog(false);

      // Reset the form
      setNewJob({
        job_code: '',
        job_name: '',
        deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
        description: '',
        high_priority: false,
        workflow_name: '',
      });
    } catch (error) {
      console.error('Error creating job:', error);
      alert('Failed to create job. Check console for details.');
    }
  };

  const filteredJobs = jobList.filter((job) => {
    if (filter === 'high_priority' && !job.high_priority) return false;
    if (
      filter === 'completed' &&
      !job.workflow.every((s) => s.progress.completed === s.progress.total)
    )
      return false;
    if (
      filter === 'in_progress' &&
      !job.workflow.some((s) => s.progress.completed > 0)
    )
      return false;
    if (
      searchTerm &&
      !job.job_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
      return false;
    return true;
  });

  return (
    <Box sx={{ padding: '20px' }}>
      {/* Filter/Search UI */}
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}
      >
        <TextField
          label="Search Jobs"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="high_priority">High Priority</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="in_progress">In Progress</MenuItem>
        </Select>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        {/* Left-aligned Grid/List toggle buttons */}
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(e, newViewMode) => setViewMode(newViewMode)}
        >
          <ToggleButton value="grid">Grid View</ToggleButton>
          <ToggleButton value="list">List View</ToggleButton>
        </ToggleButtonGroup>

        {/* Right-aligned New Job button */}
        {userRole === 'admin' && (
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpenNewJobDialog(true)}
          >
            New Job
          </Button>
        )}
      </Box>

      {/* New Job Dialog */}
      <Dialog
        open={openNewJobDialog}
        onClose={() => setOpenNewJobDialog(false)}
      >
        <DialogTitle>Create New Job</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Job Code (Optional)"
            value={newJob.job_code}
            onChange={(e) => handleNewJobChange('job_code', e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Job Name"
            value={newJob.job_name}
            onChange={(e) => handleNewJobChange('job_name', e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="dense"
            type="date"
            label="Deadline"
            value={newJob.deadline}
            onChange={(e) => handleNewJobChange('deadline', e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            fullWidth
            margin="dense"
            label="Description"
            value={newJob.description}
            onChange={(e) => handleNewJobChange('description', e.target.value)}
            multiline
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newJob.high_priority}
                onChange={(e) =>
                  handleNewJobChange('high_priority', e.target.checked)
                }
              />
            }
            label="High Priority"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Workflow</InputLabel>
            <Select
              value={newJob.workflow_name}
              onChange={(e) =>
                handleNewJobChange('workflow_name', e.target.value)
              }
              required
            >
              {workflowTemplates.map((template) => (
                <MenuItem
                  key={template.workflow_template_id}
                  value={template.workflow_name}
                >
                  {template.workflow_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewJobDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateJob}>
            Create Job
          </Button>
        </DialogActions>
      </Dialog>

      {/* Jobs Grid/List */}

      <Grid container spacing={3}>
        {filteredJobs.map((job) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={viewMode === 'grid' ? 4 : 12}
            key={job.job_id}
          >
            <Card
              elevation={3}
              onClick={() => onJobSelect(job)}
              sx={{
                position: 'relative', // Enable absolute positioning for the total products
                cursor: 'pointer',
                borderLeft: job.high_priority ? '5px solid #ff6f61' : 'none',
                '&:hover': { boxShadow: 6 },
              }}
            >
              {/* Top-right total products label */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'primary.main',
                  color: 'white',
                  borderRadius: '50%',
                  width: 30,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {job.workflow && job.workflow.length > 0
                  ? Math.max(
                      ...job.workflow.map((step) => step.progress.total),
                      0
                    )
                  : 0}
              </Box>

              <CardContent>
                <Typography variant="h6" noWrap>
                  {job.job_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Deadline: {new Date(job.deadline).toLocaleDateString()}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    job.workflow && job.workflow.length > 0
                      ? (job.workflow.reduce(
                          (sum, step) => sum + step.progress.completed,
                          0
                        ) /
                          job.workflow.reduce(
                            (sum, step) => sum + step.progress.total,
                            0
                          )) *
                        100
                      : 0
                  }
                  sx={{ marginTop: 1 }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default JobOverview;
