import React from 'react';
import { Typography, Box, Button } from '@mui/material';

function JobCreationUcustomerComponent({ config }) {
  return (
    <Box
      sx={{
        minHeight: '40vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        padding: 4,
        backgroundColor: 'background.default',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Create a Job!
      </Typography>

      {/* Snug Upgrade Content */}
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          padding: 2, // Minimal padding for snug fit
          width: 'fit-content', // Adjusts to content width
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Unlock Pro Features
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The Advanced features are part of our <strong>Pro version</strong>.
          Upgrade to access enhanced capabilities, including streamlined and customisable job
          creation workflows, customizable product templates with refernce images for the stylist and photographer, 
          collaborative tools and reports to help your team work more efficiently.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => alert('Upgrade Flow')}
        >
          Upgrade Now
        </Button>
      </Box>
    </Box>
  );
}

export default JobCreationUcustomerComponent;
