import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepComponentRenderer from './StepComponentRenderer';
import { getWorkflowStepDetails } from '../api';

const StepDetails = ({ step, job }) => {
  const [stepDefinition, setStepDefinition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStepDefinition = async () => {
      if (!step || !job) {
        console.warn('Step or Job is undefined.');
        setLoading(false);
        return;
      }

      const { customer_id, job_id, workflow_id } = job;
      const { step_id } = step;

      try {
        const details = await getWorkflowStepDetails(
          job_id,
          workflow_id,
          step_id
        );
        //console.log('Fetched step details:', details);

        if (details?.step_definition) {
          setStepDefinition(details.step_definition);
        } else {
          console.warn('No step_definition found in API response:', details);
          setStepDefinition(null);
        }
      } catch (err) {
        console.error('Error fetching step details:', err);
        setError(err);
        setStepDefinition(null);
      } finally {
        setLoading(false);
      }
    };

    fetchStepDefinition();
  }, [step, job]);

  if (loading) {
    return <div>Loading step details...</div>;
  }

  if (error) {
    return <div>Error loading step details.</div>;
  }

  if (!stepDefinition) {
    return <div>No step definition available.</div>;
  }

  // Pass `job` and `step` to `StepComponentRenderer`
  return (
    <div>
      <StepComponentRenderer
        step_definition={stepDefinition}
        step={step}
        job={job}
      />
    </div>
  );
};

StepDetails.propTypes = {
  step: PropTypes.shape({
    step_id: PropTypes.number.isRequired,
    step_name: PropTypes.string.isRequired,
  }).isRequired,
  job: PropTypes.shape({
    customer_id: PropTypes.number.isRequired,
    job_id: PropTypes.number.isRequired,
    workflow_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default StepDetails;
