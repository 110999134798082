import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JobList from './JobList';
import JobOverview from './JobOverview';
import api from '../api';
import { useCustomer } from '../context/CustomerContext'; // Import useCustomer

function CustomerMainPage() {
  const { customerId, loading } = useCustomer(); // Use customerId and loading from context
  const [customer, setCustomer] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        if (!customerId) {
          console.warn('Customer ID not available yet.');
          return; // Wait until customerId is available
        }

        console.log('Fetching customer details for ID:', customerId);

        const customerDetails = await api.getCustomerDetails(customerId); // Pass the customerId to the API call
        setCustomer(customerDetails);
      } catch (err) {
        console.error('Error fetching customer details:', err);
        setError('Failed to load customer details.');
      }
    };

    fetchCustomerDetails();
  }, [customerId]); // Dependency array ensures this effect runs whenever customerId changes

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  const handleCloseDialog = () => {
    setSelectedJob(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          padding: '20px',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Loading customer details...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          padding: '20px',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!customer) {
    return (
      <Box
        sx={{
          padding: '20px',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Loading customer details...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          StudioFlow
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {customer.customer_name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.description}
        </Typography>
      </Box>
      <JobOverview onJobSelect={handleJobSelect} />
      <Dialog
        open={Boolean(selectedJob)}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography variant="h6">Job Details</Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <JobList
            isAdmin={false}
            selectedJob={selectedJob}
            onVaultOpen={(source, selectedJob) =>
              console.log(
                `StudioVault opened from ${source} with job:`,
                selectedJob
              )
            }
            onCloseDialog={handleCloseDialog} // Pass the close dialog handler
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CustomerMainPage;
