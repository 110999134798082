import React from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';

function ProductionCalendarUadminComponent({ config }) {
  return (
    <Box>
      <Typography variant="h6">{config.title} (Uadmin)</Typography>

      {/* Admin-specific UI elements for Production Calendar */}

      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Manage Calendar
      </Button>
    </Box>
  );
}

export default ProductionCalendarUadminComponent;
