import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import stepComponents from './StepComponents';
import { getAuth } from 'firebase/auth'; // Firebase Auth for decoding JWT

const StepComponentRenderer = ({ step_definition, job, step }) => {
  const [userRole, setUserRole] = useState(null);

  //console.log(
  //  'StepComponentRenderer initialized with step_definition:',
  //  step_definition
  //;

  if (!step_definition) {
    console.error('No step_definition provided.');
    return <div>Loading step details...</div>;
  }

  const { step_type, status, config } = step_definition;

  // Log extracted fields
  //console.log('Extracted step_definition fields:', {
  //  step_type,
  // status,
  //  config,
  //});

  if (!step_type || !config) {
    console.warn('Incomplete or invalid step_definition:', step_definition);
    return <div>Invalid step details.</div>;
  }

  // Fetch user role from Firebase JWT
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (!currentUser) {
          console.error('No authenticated user found.');
          setUserRole('unknown');
          return;
        }

        // Decode the Firebase JWT to get claims
        const token = await currentUser.getIdTokenResult();
        //console.log('Decoded Firebase JWT:', token);

        // Determine role from custom claims
        const role = token.claims.role; // Expecting 'role' to be 'studio_admin' or 'customer_user'
        if (!role) {
          console.warn(
            'No role claim found in token claims. Defaulting to "unknown".'
          );
          setUserRole('unknown');
        } else {
          //console.log('Determined userRole from token claims:', role);
          setUserRole(role === 'studio_admin' ? 'admin' : 'customer');
        }
      } catch (error) {
        console.error('Error fetching user role from Firebase JWT:', error);
        setUserRole('unknown');
      }
    };

    fetchUserRole();
  }, []);

  // Wait for userRole to be determined
  if (!userRole) {
    return <div>Loading user role...</div>;
  }

  // Normalize step_type to lowercase and replace spaces with underscores
  const normalizeStepType = (type) => type.toLowerCase().replace(/\s+/g, '_');
  const componentKey = `${normalizeStepType(step_type)}_${userRole}`;

  //console.log('Normalized step_type:', normalizeStepType(step_type));
  //console.log('Generated componentKey:', componentKey);

  const Component = stepComponents[componentKey];

  if (!Component) {
    console.warn(`No component found for componentKey: ${componentKey}`);
    console.warn(
      'Available components in stepComponents:',
      Object.keys(stepComponents)
    );
    return <div>No component available for this step.</div>;
  }

  //console.log(`Rendering component for componentKey: ${componentKey}`);

  // Ensure no nested scroll containers for Droppable
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto', // Ensure this is the single scrollable container
  };

  return (
    <div style={containerStyle}>
      <Component config={config} job={job} step={step} />
    </div>
  );
};

StepComponentRenderer.propTypes = {
  step_definition: PropTypes.shape({
    step_type: PropTypes.string.isRequired,
    status: PropTypes.string,
    config: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
      // ... other config properties
    }).isRequired,
  }).isRequired,
  job: PropTypes.shape({
    customer_id: PropTypes.number.isRequired,
    job_id: PropTypes.number.isRequired,
    workflow_id: PropTypes.number.isRequired,
    // ... other job properties
  }).isRequired,
  step: PropTypes.shape({
    step_id: PropTypes.number.isRequired,
    // ... other step properties
  }).isRequired,
};

export default StepComponentRenderer;
