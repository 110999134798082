// frontend/react-app/src/components/KanbanBoard.js

import React, { useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter } from '@dnd-kit/core';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox'; // For Select All (filled checkbox)
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { useDroppable } from '@dnd-kit/core';

function KanbanBoard({
  statuses,
  products,
  onDragEnd,
  onIndividualEdit,
  onBulkEdit,
  defaultStatus,
  onDeleteRequest,
  customerId,
  jobId,
  styleTemplates,
}) {
  const [selectedProducts, setSelectedProducts] = useState({}); // { status1: [id1, id2], status2: [...] }
  const [individualEditDialog, setIndividualEditDialog] = useState({
    open: false,
    productId: null,
  });
  const [bulkEditDialog, setBulkEditDialog] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');

  // Group products by their status
  const groupedProducts = statuses.reduce((acc, status) => {
    acc[status] = products.filter(
      (product) => (product.status || defaultStatus) === status
    );
    return acc;
  }, {});

  // Handlers for Individual Edit Dialog
  const openIndividualEditDialog = (productId) => {
    setIndividualEditDialog({ open: true, productId });
  };

  const closeIndividualEditDialog = () => {
    setIndividualEditDialog({ open: false, productId: null });
    setSelectedTemplateId('');
  };

  const handleIndividualEditSave = () => {
    if (selectedTemplateId && individualEditDialog.productId) {
      onIndividualEdit(individualEditDialog.productId, selectedTemplateId);
    }
    closeIndividualEditDialog();
  };

  // Handlers for Bulk Edit Dialog
  const openBulkEditDialog = () => {
    setBulkEditDialog(true);
  };

  const closeBulkEditDialog = () => {
    setBulkEditDialog(false);
    setSelectedTemplateId('');
  };

  const handleBulkEditSave = (e) => {
    e.stopPropagation(); // Prevents event bubbling
    e.preventDefault(); // Prevents default form behavior if applicable

    const selectedProductIds = Object.values(selectedProducts).flat();

    // Ensure that selectedTemplateId is passed correctly
    console.log('Bulk Edit Save: Selected Product IDs:', selectedProductIds);
    console.log('Bulk Edit Save: Selected Template ID:', selectedTemplateId);

    if (selectedTemplateId && selectedProductIds.length > 0) {
      onBulkEdit(selectedProductIds, selectedTemplateId);
    }
    closeBulkEditDialog();
  };

  // Select/Deselect Handlers
  const handleSelectAll = (status) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [status]: groupedProducts[status].map((product) => product.product_id),
    }));
  };

  const handleDeselectAll = (status) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [status]: [],
    }));
  };

  const handleSelectProduct = (status, productId) => {
    setSelectedProducts((prev) => {
      const currentSelected = prev[status] || [];
      if (currentSelected.includes(productId)) {
        // Deselect
        return {
          ...prev,
          [status]: currentSelected.filter((id) => id !== productId),
        };
      } else {
        // Select
        return {
          ...prev,
          [status]: [...currentSelected, productId],
        };
      }
    });
  };

  // Drag and Drop Handler
  const handleDragEndInternal = (event) => {
    const { active, over } = event;

    if (!over) {
      console.warn('Drag end without a drop target.');
      return;
    }

    const productId = parseInt(active.id, 10);
    const newStatus =
      statuses.find((status) => status === String(over.id)) || defaultStatus;

    console.log(
      `Dragged Product ID: ${productId}, Target Status: ${newStatus}`
    );

    const draggedStatus = Object.keys(groupedProducts).find((status) =>
      groupedProducts[status].some((p) => p.product_id === productId)
    );

    console.log(`Dragged Product's Original Status: ${draggedStatus}`);

    // Use only selected products if explicitly selected; otherwise, treat as individual.
    const productsToMove =
      selectedProducts[draggedStatus]?.includes(productId) &&
      selectedProducts[draggedStatus]?.length > 1
        ? selectedProducts[draggedStatus]
        : [productId];

    console.log('Products to Move:', productsToMove);

    // Pass correct type to onDragEnd
    if (productsToMove.length > 1) {
      onDragEnd(productsToMove, newStatus); // Bulk move
    } else {
      onDragEnd(productsToMove[0], newStatus); // Individual move
    }
  };

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEndInternal}
      >
        <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', padding: 2 }}>
          {statuses.map((status) => {
            const isAllSelected =
              groupedProducts[status].length > 0 &&
              selectedProducts[status]?.length ===
                groupedProducts[status].length;
            const hasSelection = selectedProducts[status]?.length > 0;

            return (
              <DroppableContainer key={status} id={status}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {status}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {/* Select All */}
                    <IconButton
                      onClick={() => handleSelectAll(status)}
                      disabled={
                        groupedProducts[status].length ===
                        selectedProducts[status]?.length
                      }
                      aria-label="select all"
                    >
                      <CheckBoxIcon />
                    </IconButton>

                    {/* Deselect All */}
                    <IconButton
                      onClick={() => handleDeselectAll(status)}
                      disabled={!hasSelection}
                      aria-label="deselect all"
                    >
                      <ClearIcon />
                    </IconButton>

                    {/* Bulk Edit */}
                    <IconButton
                      onClick={openBulkEditDialog}
                      disabled={!hasSelection}
                      aria-label="bulk edit"
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>

                <SortableContext
                  items={groupedProducts[status].map((product) => {
                    if (!product.product_id) {
                      console.error('Product missing product_id:', product);
                      return 'undefined';
                    }
                    return product.product_id.toString();
                  })}
                  strategy={verticalListSortingStrategy}
                >
                  {groupedProducts[status].map((product) => (
                    <SortableItem
                      key={product.product_id}
                      product={product}
                      isSelected={selectedProducts[status]?.includes(
                        product.product_id
                      )}
                      onSelect={() =>
                        handleSelectProduct(status, product.product_id)
                      }
                      onDelete={() => onDeleteRequest(product)}
                      onEdit={() =>
                        openIndividualEditDialog(product.product_id)
                      }
                      styleTemplates={styleTemplates}
                    />
                  ))}
                </SortableContext>
              </DroppableContainer>
            );
          })}
        </Box>
      </DndContext>

      {/* Individual Edit Dialog */}
      <Dialog
        open={individualEditDialog.open}
        onClose={closeIndividualEditDialog}
      >
        <DialogTitle>Edit Style Template</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="individual-template-label">
              Style Template
            </InputLabel>
            <Select
              labelId="individual-template-label"
              value={selectedTemplateId}
              onChange={(e) => setSelectedTemplateId(e.target.value)}
            >
              {styleTemplates.map((template) => (
                <MenuItem
                  key={template.style_template_id}
                  value={template.style_template_id}
                >
                  {template.template_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIndividualEditDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleIndividualEditSave}
            color="primary"
            disabled={!selectedTemplateId}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Edit Dialog */}
      <Dialog open={bulkEditDialog} onClose={closeBulkEditDialog}>
        <DialogTitle>Bulk Edit Style Template</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="bulk-template-label">Style Template</InputLabel>
            <Select
              labelId="bulk-template-label"
              value={selectedTemplateId}
              onChange={(e) => setSelectedTemplateId(e.target.value)}
            >
              {styleTemplates.map((template) => (
                <MenuItem
                  key={template.style_template_id}
                  value={template.style_template_id}
                >
                  {template.template_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBulkEditDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleBulkEditSave}
            color="primary"
            disabled={!selectedTemplateId}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function DroppableContainer({ id, children }) {
  const { isOver, setNodeRef } = useDroppable({ id });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        width: 300,
        background: isOver ? '#d0f0d0' : '#f4f4f4',
        minHeight: 200,
        padding: 2,
        borderRadius: 2,
        border: isOver ? '2px solid green' : '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
}

function SortableItem({
  product,
  isSelected,
  onSelect,
  onDelete,
  onEdit,
  styleTemplates,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: product.product_id.toString(),
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: '8px 0',
    border: isSelected ? '2px solid blue' : '1px solid #ccc',
  };

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        ...style,
        padding: 2,
        background: '#ffffff',
        boxShadow: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Checkbox
        checked={!!isSelected} // Ensure it is always a boolean
        onChange={(e) => {
          e.stopPropagation();
          onSelect();
        }}
        inputProps={{ 'aria-label': 'select product' }}
        sx={{ marginRight: 1 }}
      />
      <IconButton
        {...listeners}
        {...attributes}
        size="small"
        sx={{ cursor: 'grab', marginRight: 1 }}
      >
        <DragIndicatorIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {product.product_name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          SKU: {product.product_code}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', fontStyle: 'italic' }}
        >
          Template: {product.template || 'Default'}
        </Typography>
      </Box>
      <Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          size="small"
          color="error"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          size="small"
        >
          <EditIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}

export default KanbanBoard;
