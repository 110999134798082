// frontend/react-app/src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdTokenResult();
          const role = token.claims.role;

          if (role) {
            setUserRole(role === 'studio_admin' ? 'admin' : 'customer');
          } else {
            console.error('No role claim found in JWT claims.');
            setUserRole('unknown');
          }
        } catch (error) {
          console.error('Error fetching user role from JWT claims:', error);
        }
      } else {
        console.error('No authenticated user found.');
        setUserRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ userRole, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
