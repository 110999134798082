import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  TextField,
  Typography,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import api from '../api';
import StudioVaultDialog from './StudioVaultDialog';
import UpgradeDialog from './UpgradeDialog';
import { useCustomer } from '../context/CustomerContext';

function StudioVaultMainPage() {
  const { customerId, loading } = useCustomer();
  const [tabIndex, setTabIndex] = useState(0);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [isStudioVaultDialogOpen, setIsStudioVaultDialogOpen] = useState(false);
  const [basicFields, setBasicFields] = useState({
    product_code: '',
    product_name: '',
    product_description: '',
    uploaded_from: '',
    uploaded_to: '',
  });
  const [advancedFields, setAdvancedFields] = useState({
    brand: '',
    category: '',
    high_priority: false,
    style_template_id: '',
    uploaded_by: '',
    shot_type_id: '',
  });
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);
  const [studioVaultParams, setStudioVaultParams] = useState({});

  /*useEffect(() => {
    const customerId = localStorage.getItem('customer_id'); // Retrieve customer ID from localStorage

    if (!customerId) {
      setError('No customer ID found.');
      return;
    }

    const fetchCustomerDetails = async () => {
      try {
        const customerDetails = await api.getCustomerDetails(customerId); // Use centralized API
        setCustomer(customerDetails);
      } catch (err) {
        console.error('Error fetching customer details:', err);
        setError('Failed to load customer details.');
      }
    };

    fetchCustomerDetails();
  }, []);*/
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        if (!customerId) {
          console.warn('Customer ID not available yet.');
          return; // Wait until customerId is available
        }

        console.log('Fetching customer details for ID:', customerId);

        const customerDetails = await api.getCustomerDetails(customerId); // Pass the customerId to the API call
        setCustomer(customerDetails);
      } catch (err) {
        console.error('Error fetching customer details:', err);
        setError('Failed to load customer details.');
      }
    };

    fetchCustomerDetails();
  }, [customerId]);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      // Show the upgrade dialog when the Advanced tab is clicked
      setIsUpgradeDialogOpen(true);
    } else {
      // Switch to the Basic tab
      setTabIndex(newValue);
    }
  };

  const handleBasicChange = (e) =>
    setBasicFields({ ...basicFields, [e.target.name]: e.target.value });

  const handleAdvancedChange = (e) =>
    setAdvancedFields({
      ...advancedFields,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });

  const handleSearch = () => {
    const params = {
      customer_id: customer.customer_id, // Always include customer_id
      ...Object.fromEntries(
        Object.entries({ ...basicFields, ...advancedFields }).filter(
          ([_, value]) => value !== '' && value !== null && value !== false
        )
      ),
    };

    console.log(
      'Opening StudioVaultDialog with:',
      JSON.stringify(params, null, 2)
    );
    console.log('Customer object:', customer);
    console.log('Basic Fields:', basicFields);
    console.log('Advanced Fields:', advancedFields);

    setStudioVaultParams(params);
    setIsStudioVaultDialogOpen(true);
  };

  if (error) {
    return (
      <Box
        sx={{
          padding: '20px',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!customer) {
    return (
      <Box
        sx={{
          padding: '20px',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Loading customer details...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          StudioVault
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {customer.customer_name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.description}
        </Typography>
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{
          marginBottom: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Tab label="Basic" />
        <Tab label="Advanced (Pro)" />
      </Tabs>
      {tabIndex === 0 && (
        <Box>
          {['product_code', 'product_name', 'product_description'].map(
            (field) => (
              <TextField
                key={field}
                label={field.replace('_', ' ')} // Lowercase placeholder
                name={field}
                value={basicFields[field]}
                onChange={handleBasicChange}
                fullWidth
                margin="normal"
              />
            )
          )}
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}
          >
            <TextField
              label="Date from"
              name="uploaded_from"
              type="date"
              value={basicFields.uploaded_from}
              onChange={handleBasicChange}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
            />
            <Typography sx={{ whiteSpace: 'nowrap' }}>to</Typography>
            <TextField
              label="Date to"
              name="uploaded_to"
              type="date"
              value={basicFields.uploaded_to}
              onChange={handleBasicChange}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
            />
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box>
          {[
            'brand',
            'category',
            'style_template_id',
            'uploaded_by',
            'shot_type_id',
          ].map((field) => (
            <TextField
              key={field}
              label={field.replace('_', ' ')} // Lowercase placeholder
              name={field}
              value={advancedFields[field]}
              onChange={handleAdvancedChange}
              fullWidth
              margin="normal"
            />
          ))}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <Typography>High Priority</Typography>
            <Switch
              name="high_priority"
              checked={advancedFields.high_priority}
              onChange={handleAdvancedChange}
              sx={{ marginLeft: 2 }}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="contained"
          sx={{
            paddingX: 4, // Horizontal padding
            paddingY: 1.5, // Vertical padding
            fontSize: '1rem', // Adjust font size to match app styling
          }}
          onClick={handleSearch}
        >
          StudioVault
        </Button>
      </Box>

      {isStudioVaultDialogOpen && (
        <StudioVaultDialog
          open={isStudioVaultDialogOpen}
          onClose={() => setIsStudioVaultDialogOpen(false)}
          params={studioVaultParams}
          //apiMode="vaultSearch"
          customerId={customer.customer_id}
        />
      )}

      {/* Upgrade Dialog */}
      {/* Reusable Upgrade Dialog */}
      <UpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={() => setIsUpgradeDialogOpen(false)}
        onUpgrade={() => alert('Upgrade Flow')}
      />
    </Box>
  );
}

export default StudioVaultMainPage;
