import React from 'react';
import UadminBaseComponent from './UadminBaseComponent';
import {
  fetchProductStatuses,
  updateProductStatus,
  updateBulkProductStatus,
} from '../../api';

function RetouchUadminComponent({ job, step }) {
  const fetchStatuses = () =>
    fetchProductStatuses(
      //job.customer_id,
      job.job_id,
      job.workflow_id,
      step.step_id
    );

  const updateStatus = (productId, newStatus) =>
    updateProductStatus({
      ...job,
      step_id: step.step_id,
      product_id: productId,
      new_status: newStatus,
    });

  const updateBulkStatus = (productIds, newStatus) =>
    updateBulkProductStatus({
      ...job,
      step_id: step.step_id,
      product_ids: productIds,
      new_status: newStatus,
    });

  return (
    <UadminBaseComponent
      stepTitle="Publish"
      fetchStatuses={fetchStatuses}
      updateStatus={updateStatus}
      updateBulkStatus={updateBulkStatus}
      dialogMessages={{
        fetchError: 'Failed to fetch products for Retouch step.',
      }}
    />
  );
}

export default RetouchUadminComponent;
