import React, { useState, useEffect } from 'react';
import KanbanBoardGeneric from '../KanbanBoardGeneric';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';

function UadminBaseComponent({
  stepTitle,
  fetchStatuses,
  updateStatus,
  updateBulkStatus,
  dialogMessages = {},
  handleIndividualEdit,
  handleBulkEdit,
  handleDeleteRequest,
}) {
  const [products, setProducts] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [dialog, setDialog] = useState({ open: false, message: '' });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  useEffect(() => {
    fetchStatuses()
      .then(({ products = [], possible_statuses = [] }) => {
        setProducts(products);
        setStatuses(possible_statuses);
      })
      .catch(() =>
        setDialog({
          open: true,
          message: dialogMessages.fetchError || 'Failed to fetch products.',
        })
      );
  }, [fetchStatuses, dialogMessages]);

  const handleDragEnd = (productId, newStatus) => {
    const product = products.find((p) => p.product_id === productId);
    if (!product || product.status === newStatus) return;

    setProducts((prev) =>
      prev.map((p) =>
        p.product_id === productId ? { ...p, status: newStatus } : p
      )
    );

    updateStatus(productId, newStatus)
      .then(() =>
        setSnackbar({
          open: true,
          message: `Product "${product.product_name}" moved to "${newStatus}".`,
          severity: 'success',
        })
      )
      .catch(() => {
        setProducts((prev) =>
          prev.map((p) =>
            p.product_id === productId ? { ...p, status: product.status } : p
          )
        );
        setSnackbar({
          open: true,
          message: `Failed to update product "${product.product_name}".`,
          severity: 'error',
        });
      });
  };

  const handleBulkMove = (productIds, newStatus) => {
    const initialStates = products.reduce((acc, p) => {
      if (productIds.includes(p.product_id)) acc[p.product_id] = p.status;
      return acc;
    }, {});

    setProducts((prev) =>
      prev.map((p) =>
        productIds.includes(p.product_id) ? { ...p, status: newStatus } : p
      )
    );

    updateBulkStatus(productIds, newStatus)
      .then(() =>
        setSnackbar({
          open: true,
          message: `${productIds.length} products updated to "${newStatus}".`,
          severity: 'success',
        })
      )
      .catch(() => {
        setProducts((prev) =>
          prev.map((p) =>
            productIds.includes(p.product_id)
              ? { ...p, status: initialStates[p.product_id] }
              : p
          )
        );
        setSnackbar({
          open: true,
          message: `Bulk update failed.`,
          severity: 'error',
        });
      });
  };

  const defaultHandleIndividualEdit = (product) => {
    setDialog({
      open: true,
      message: `You are about to edit product "${product.product_name}".`,
    });
  };

  const defaultHandleBulkEdit = (status) => {
    const selectedProductIds = selectedProducts[status] || [];
    if (!selectedProductIds.length) {
      setDialog({
        open: true,
        message: 'No products selected for bulk editing.',
      });
      return;
    }

    const productNames = products
      .filter((p) => selectedProductIds.includes(p.product_id))
      .map((p) => p.product_name)
      .join(', ');

    setDialog({
      open: true,
      message: `Editing ${selectedProductIds.length} product(s) in status "${status}": ${productNames}.`,
    });
  };

  const defaultHandleDeleteRequest = () => {
    setDialog({
      open: true,
      message: 'No deletions are allowed at this step.',
    });
  };

  const handleSelectProduct = (status, productId) => {
    setSelectedProducts((prev) => {
      const selected = prev[status] || [];
      return {
        ...prev,
        [status]: selected.includes(productId)
          ? selected.filter((id) => id !== productId)
          : [...selected, productId],
      };
    });
  };

  const handleSelectAll = (status) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [status]: products
        .filter((p) => p.status === status)
        .map((p) => p.product_id),
    }));
  };

  const handleDeselectAll = (status) => {
    setSelectedProducts((prev) => ({ ...prev, [status]: [] }));
  };

  return (
    <Box sx={{ padding: 3, textAlign: 'center' }}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {stepTitle}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        ({products.length} Products)
      </Typography>
      <KanbanBoardGeneric
        statuses={statuses}
        products={products}
        onDragEnd={(ids, newStatus) =>
          Array.isArray(ids)
            ? handleBulkMove(ids, newStatus)
            : handleDragEnd(ids, newStatus)
        }
        onIndividualEdit={(product) => {
          if (handleIndividualEdit) {
            handleIndividualEdit(product, setDialog);
          } else {
            defaultHandleIndividualEdit(product);
          }
        }}
        onBulkEdit={(status) => {
          const selectedProductIds = selectedProducts[status] || [];
          const selectedProductObjects =
            products.filter((p) => selectedProductIds.includes(p.product_id)) ||
            [];

          if (handleBulkEdit) {
            handleBulkEdit(status, selectedProductObjects, setDialog);
          } else {
            defaultHandleBulkEdit(status);
          }
        }}
        onDeleteRequest={() => {
          if (handleDeleteRequest) {
            handleDeleteRequest(setDialog); // Pass `setDialog` for custom implementations
          } else {
            defaultHandleDeleteRequest(); // Fallback to default
          }
        }}
        selectedProducts={selectedProducts}
        onSelectProduct={handleSelectProduct}
        onSelectAll={handleSelectAll}
        onDeselectAll={handleDeselectAll}
      />
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, message: '' })}
      >
        <DialogTitle>Notice</DialogTitle>
        <DialogContent>{dialog.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog({ open: false, message: '' })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}

export default UadminBaseComponent;
